import { styled, TableCell } from '@mui/material';

export const MultipleInputsCell = styled(TableCell)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: relative;
`;

export const CogsCell = styled(MultipleInputsCell)`
  min-width: 330px;
  border-right: 1px dashed lightgray;
`;
