import { LabelledOutline } from '../../../../components/Form/LabeledOutline';
import React, { useEffect, useState } from 'react';
import { formatToUSD } from '../../../../utils/helpers';
import { styled } from '@mui/material';

const ButtonCellStyle = styled(LabelledOutline)`
  cursor: ${(props: { disabled: boolean }) => (props.disabled ? 'initial' : 'pointer')};
`;

export const ButtonCell = ({ value, onClick, disabled, ...otherProps }: any) => {
  const [formattedValue, setFormattedValue] = useState('');

  useEffect(() => {
    setFormattedValue(formatToUSD(value));
  }, [value]);

  return (
    <ButtonCellStyle
      onClick={(e: any) => {
        e.preventDefault();
        !disabled && onClick();
      }}
      disabled={disabled}
      {...otherProps}
    >
      {formattedValue}
    </ButtonCellStyle>
  );
};
