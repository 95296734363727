import React from 'react';

// material-ui
import { makeStyles } from '@mui/styles';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import MainCard from './MainCard';

// project imports

// assets

// style constant
const useStyles = makeStyles((theme) => ({
  card: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
    backgroundColor: theme.palette.paper,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
    color: theme.palette.primary.light,
    overflow: 'hidden',
    position: 'relative',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
    margin: (narrow) => `${theme.spacing(narrow ? 0.5 : 2.5, 'auto')} !important`,
    boxShadow: '0px 4px 20px 0 rgb(176 186 201 / 75%)',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      background: `linear-gradient(210.04deg, ${theme.palette.primary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
      borderRadius: '50%',
      top: '-30px',
      right: '-180px',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      background: `linear-gradient(140.9deg, ${theme.palette.primary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
      borderRadius: '50%',
      top: '-160px',
      right: '-130px',
    },
  },
  content: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
    padding: (narrow) => `${theme.spacing(narrow ? 0.5 : 2.5, 2.5)} !important`,
  },
  avatar: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'typography' does not exist on type 'Defa... Remove this comment to see the full error message
    ...theme.typography.commonAvatar,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'typography' does not exist on type 'Defa... Remove this comment to see the full error message
    ...theme.typography.largeAvatar,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
    backgroundColor: theme.palette.primary[900],
    color: '#fff',
  },
  primary: {
    color: '#fff',
  },
  secondary: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
    color: theme.palette.primary.paper,
    marginTop: '5px',
    letterSpacing: 0.4,
  },
  padding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

// ===========================|| DASHBOARD - TOTAL INCOME DARK CARD ||=========================== //

const NoteCard = ({ children, Icon, narrow = false }: any) => {
  const classes = useStyles(narrow);

  return (
    <MainCard boxShadow border={false} className={classes.card} contentClass={classes.content}>
      <List className={classes.padding}>
        <ListItem alignItems="center" disableGutters className={classes.padding}>
          {Icon ? (
            <ListItemAvatar>
              <Avatar variant="rounded" className={classes.avatar}>
                <Icon fontSize="inherit" />
              </Avatar>
            </ListItemAvatar>
          ) : null}
          <ListItemText
            className={classes.padding}
            sx={{
              mt: 0.45,
              mb: 0.45,
            }}
            // primary={
            // 	<Typography variant="h4" className={classes.primary}>
            // 		$203k
            // 	</Typography>
            // }
            secondary={
              <Typography variant="subtitle1" className={classes.secondary}>
                {children}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </MainCard>
  );
};

export default NoteCard;
