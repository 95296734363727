import { useDispatch, useSelector } from 'react-redux';
import { getInventoryAsync, selectAvailableYears, selectFilters, setFilters } from '../inventorySlice';
import { Box, IconButton } from '@mui/material';
import Select from '../../../components/Form/Select';
import { IconSettings } from '../../../components/ColoredIcon';
import { setOpen } from '../../settings/settingsSlice';
import { useEffect } from 'react';

function InventoryFilters() {
  const dispatch = useDispatch();
  const storeInventoryFilters = useSelector(selectFilters);
  const storeAvailableYears = useSelector(selectAvailableYears);

  const filterChangeHandler = (name: any, value: any) => {
    dispatch(setFilters({ ...storeInventoryFilters, [name]: value }));
  };

  const toggleDrawer = () => {
    dispatch(setOpen(true));
  };

  useEffect(() => {
    dispatch(getInventoryAsync());
  }, [dispatch]);

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box width="150px">
        <Select
          options={storeAvailableYears}
          value={storeAvailableYears.length ? storeInventoryFilters.year : ''}
          getOptionLabel={(option) => option.toString()}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => filterChangeHandler('year', value)}
          label="Year"
        />
      </Box>
      <IconButton onClick={toggleDrawer} aria-label="delete">
        <IconSettings />
      </IconButton>
    </Box>
  );
}

export default InventoryFilters;
