import { Box, FormControlLabel, FormGroup, styled, Switch, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ConfirmationModal from '../../../../components/Modal/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { selectChannels, updateChannelAsync } from '../../../products/productsSlice';
import type { ProductSalesChannel } from '../../../../types';
import { resetManualOverriddenPurchasePrice, selectFilters, updateSalesAsync } from '../../salesSlice';
import { monthsList } from '../../../../utils/months';
import { selectCanUpdateInventorySales } from '../../../inventory/inventorySlice';
import { getDaysInMonth, setMonth } from 'date-fns';

const SwitchContainer = styled(Box)`
  top: 12px;
  right: -45px;
`;

export const SyncPurchasePrices = () => {
  const allMonths = useRef(monthsList);
  const [confirmSync, setConfirmSync] = useState(false);
  const dispatch = useDispatch();
  const storeChannels = useSelector(selectChannels, isEqual);
  const storeSalesFilters = useSelector(selectFilters, isEqual);
  const shopifyChannel = storeChannels.find((ch: ProductSalesChannel) => ch.channel === 'Shopify');
  const [isPurchaseUpdatablePriceFromChannel, setIsPurchaseUpdatablePriceFromChannel] = useState(
    shopifyChannel.updatePurchasePriceFromChannel,
  );
  const isInventoryApprovedByUser = useSelector(selectCanUpdateInventorySales);

  const [currentMonth, setCurrentMonth] = useState(allMonths.current[storeSalesFilters.month - 1]);
  const [lastDay, setLastDay] = useState<number | undefined>();

  useEffect(() => {
    setLastDay(getDaysInMonth(setMonth(new Date(), storeSalesFilters.month - 1)));
    setCurrentMonth(allMonths.current[storeSalesFilters.month - 1]);
  }, [storeSalesFilters.month]);

  const onSetIsPurchaseUpdatablePriceFromChannel = async () => {
    setConfirmSync(true);
  };

  useEffect(() => {
    if (isPurchaseUpdatablePriceFromChannel !== shopifyChannel.updatePurchasePriceFromChannel) {
      const updatedChannel = {
        ...shopifyChannel,
        updatePurchasePriceFromChannel: isPurchaseUpdatablePriceFromChannel,
      };

      if (!shopifyChannel.updatePurchasePriceFromChannel) {
        dispatch(resetManualOverriddenPurchasePrice({}));
        // @ts-ignore
        dispatch(updateChannelAsync(updatedChannel)).then(() => {
          dispatch(updateSalesAsync());
        });
      } else {
        // @ts-ignore
        dispatch(updateChannelAsync(updatedChannel));
      }
    }
  }, [dispatch, isPurchaseUpdatablePriceFromChannel, shopifyChannel]);

  return !isInventoryApprovedByUser ? (
    <>
      <SwitchContainer display="flex" alignItems="center" position="absolute" width="150px">
        <FormGroup>
          <Typography>Shopify Sync</Typography>
          <Switch
            sx={{ margin: 'auto' }}
            checked={isPurchaseUpdatablePriceFromChannel}
            onChange={onSetIsPurchaseUpdatablePriceFromChannel}
          />
        </FormGroup>
      </SwitchContainer>

      <ConfirmationModal
        open={confirmSync}
        onCancel={() => {
          setConfirmSync(false);
        }}
        onConfirm={() => {
          setConfirmSync(false);
          setIsPurchaseUpdatablePriceFromChannel(!isPurchaseUpdatablePriceFromChannel);
        }}
        cancelText="Cancel"
        confirmText="Confirm"
      >
        {isPurchaseUpdatablePriceFromChannel ? (
          <Typography>
            Turning Shopify Sync off will stop updating data from Shopify. Changes you make here will apply to all open
            months on the tracker.
          </Typography>
        ) : (
          <Typography>
            Turning Shopify Sync on will update costs per Shopify as of {lastDay}-{currentMonth} and any previous
            changes will be overridden. This will apply to all open months on the tracker.
          </Typography>
        )}
        <br />
        <br />
        <br />
      </ConfirmationModal>
    </>
  ) : null;
};
