import Api from '../../services/api';

export function fetchInventory() {
    return Api.get('inventory-per-month').then((response: any) => response);
}

export function updateInventory(updatedInventory: any) {
    return Api.put('inventory-per-month', updatedInventory).then((response: any) => response);
}

export function updateInventoryPurchase(updatedInventory: any) {
    return Api.put('inventory-per-month/purchases', updatedInventory).then((response: any) => response);
}
