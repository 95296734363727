import { Box, styled, Typography } from '@mui/material';
import { formatToUSD } from '../../../utils/helpers';
import React, { useEffect, useState } from 'react';
import InputCell from '../../../components/Form/InputCell';
import { selectFilters, selectInventory } from '../inventorySlice';
import { useSelector } from 'react-redux';
import { InventoryTableCell, InventoryTableRow } from './TableParts';
import { MultipleInputsCell } from './TableParts/MultipleInputsCells';
import { selectIsManufacturer } from '../../settings/settingsSlice';
import { InventoryPerMonthRecord } from '../../../types';

const SummaryCogsCell = styled(MultipleInputsCell)`
  border-bottom: 2px solid lightgray;
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
`;

const transformInputValueToAmount = (inventoryAmount: any) => (inventoryAmount || Number.isNaN(inventoryAmount)) ?? 0;
const initialInventorySum = {
  cogsSum: 0,
  giveawaysSum: 0,
  deadInventorySum: 0,
  purchasesSum: 0,
  endingBalanceAdjustmentsSum: 0,
};

export default function InventorySummaryListRow() {
  const storeInventory: InventoryPerMonthRecord[] = useSelector(selectInventory);
  const storeInventoryFilters = useSelector(selectFilters);
  const [inventorySummary, setInventorySummary] = useState(initialInventorySum);
  const isManufacturer = useSelector(selectIsManufacturer);

  const [headerText, setHeaderText] = useState('Summary');
  useEffect(() => {
    const title = storeInventory.length === 12 ? `${storeInventoryFilters.year} Total` : `YTD Summary`;
    if (title !== headerText && storeInventory.length) {
      setHeaderText(title);
    }

    setInventorySummary(
      storeInventory.reduce(
        (inventorySum, monthlyInventory) => ({
          cogsSum: transformInputValueToAmount(monthlyInventory.cogs) + inventorySum.cogsSum,
          giveawaysSum: transformInputValueToAmount(monthlyInventory.giveawayInventoryCost) + inventorySum.giveawaysSum,
          deadInventorySum:
            transformInputValueToAmount(monthlyInventory.deadInventoryCost) + inventorySum.deadInventorySum,
          purchasesSum:
            transformInputValueToAmount(monthlyInventory.purchasedInventoryCost) + inventorySum.purchasesSum,
          endingBalanceAdjustmentsSum:
            transformInputValueToAmount(monthlyInventory.endingBalanceAdjustments) +
            inventorySum.endingBalanceAdjustmentsSum,
        }),
        { ...initialInventorySum },
      ),
    );
  }, [storeInventory, storeInventoryFilters.year]);

  return (
    <InventoryTableRow>
      <InventoryTableCell
        sx={{
          borderRight: '1px dashed lightgray',
          paddingTop: '46px !important',
          borderBottom: '2px solid lightgray',
          borderLeft: '2px solid lightgray',
          borderTop: '2px solid lightgray',
        }}
        scope="row"
      >
        <Typography align="center">{headerText}</Typography>
      </InventoryTableCell>

      {isManufacturer ? (
        <MultipleInputsCell
          align="center"
          sx={{
            borderRight: '1px dashed lightgray',
            borderBottom: '2px solid lightgray',
            borderTop: '2px solid lightgray',
          }}
        >
          <Box display="flex" flexDirection="column" sx={{ display: 'flex', pt: 2, pb: 2, gap: 2 }}>
            <InputCell
              value={storeInventory.length && storeInventory[storeInventory.length - 1].initialSuppliesAndMaterialsCost}
              format
              type="number"
              disabled
              label="Supplies & Materials"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <InputCell
              value={storeInventory.length && storeInventory[storeInventory.length - 1].initialInventoryCost}
              format
              type="number"
              disabled
              label="Ready for Sale"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </MultipleInputsCell>
      ) : (
        <InventoryTableCell
          sx={{
            borderRight: '1px dashed lightgray',
            borderBottom: '2px solid lightgray',
            borderTop: '2px solid lightgray',
          }}
          align="center"
        >
          <InputCell
            value={storeInventory.length && storeInventory[storeInventory.length - 1].initialInventoryCost}
            format
            type="number"
            sx={{ pt: 2 }}
            disabled
          />
        </InventoryTableCell>
      )}

      <InventoryTableCell
        align="center"
        sx={{
          borderRight: '1px dashed lightgray',
          borderBottom: '2px solid lightgray',
          borderTop: '2px solid lightgray',
        }}
      >
        <InputCell
          value={inventorySummary.purchasesSum}
          format
          type="number"
          sx={{ pt: 2 }}
          disabled
        />
      </InventoryTableCell>

      <InventoryTableCell
        align="center"
        sx={{
          borderRight: '1px dashed lightgray',
          borderBottom: '2px solid lightgray',
          borderTop: '2px solid lightgray',
        }}
      >
        <InputCell
          value={inventorySummary.cogsSum}
          format
          type="number"
          sx={{ pt: 2 }}
          disabled
        />
      </InventoryTableCell>

      <SummaryCogsCell align="center">
        <Box display="flex" flexDirection="column" sx={{ display: 'flex', pt: 2, pb: 2, gap: 2 }}>
          <InputCell
            value={inventorySummary.giveawaysSum}
            format
            type="number"
            label="Giveaways"
            InputLabelProps={{
              shrink: true,
            }}
            disabled
          />

          <InputCell
            value={inventorySummary.deadInventorySum}
            format
            type="number"
            label="Dead Inventory"
            InputLabelProps={{
              shrink: true,
            }}
            disabled
          />
        </Box>
      </SummaryCogsCell>

      <InventoryTableCell
        align="center"
        sx={{
          borderRight: '1px dashed lightgray',
          borderLeft: '1px dashed lightgray',
          borderBottom: '2px solid lightgray',
          borderTop: '2px solid lightgray',
        }}
      >
        <InputCell
          value={inventorySummary.endingBalanceAdjustmentsSum}
          format
          type="number"
          sx={{ pt: 2 }}
          disabled
        />
      </InventoryTableCell>

      {isManufacturer ? (
        <MultipleInputsCell
          align="center"
          sx={{ borderBottom: '2px solid lightgray', borderTop: '2px solid lightgray' }}
        >
          <Box display="flex" flexDirection="column" sx={{ display: 'flex', pt: 2, pb: 2, gap: 2 }}>
            <InputCell
              value={storeInventory.length && storeInventory[0].suppliesAndMaterialsCost}
              format
              type="number"
              disabled
              label="Supplies & Materials"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <InputCell
              value={storeInventory.length && storeInventory[0].endInventoryCost}
              format
              type="number"
              disabled
              label="Ready for Sale"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </MultipleInputsCell>
      ) : (
        <InventoryTableCell
          sx={{ borderBottom: '2px solid lightgray', borderTop: '2px solid lightgray' }}
          align="center"
        >
          <InputCell
            sx={{ pt: 2 }}
            value={storeInventory.length && storeInventory[0].endInventoryCost}
            format
            type="number"
            disabled
          />
        </InventoryTableCell>
      )}

      <InventoryTableCell
        sx={{
          borderBottom: '2px solid lightgray',
          borderRight: '2px solid lightgray',
          borderTop: '2px solid lightgray',
          pr: 2,
        }}
        align="center"
      />
    </InventoryTableRow>
  );
}
