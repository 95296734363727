import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectInventory,
  setInventory,
  setValidating,
  updateInventoryAsync,
  updateInventoryRecord,
} from './inventorySlice';

import { Alert, CardContent, Divider, Grid, styled } from '@mui/material';
import MainCard from 'components/cards/MainCard';

import InventoryFilters from './InventoryFilters/InventoryFilters';
import InventoryList from './InventoryList/InventoryList';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { ApproveButtons } from './ApproveButtons';
import { CogsCalculatorDialog, HelpNote, TopTitle } from './Components';
import { EndingBalanceAdjustmentDialog } from './InventoryList/TableParts/EndingBalanceAdjustmentDialog';
import { InventoryPerMonthRecord } from '../../types';
import { selectIsManufacturer } from '../settings/settingsSlice';
import { isEqual } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import PurchaseInput from './purchasesInput/PurchasesInput';

const MainWrapper = styled('div')`
  max-width: 1200px;
  margin: auto;
`;

export default function Inventory({ onCalculatorClick }: any) {
  const storeInventory = useSelector(selectInventory, isEqual);
  const dispatch = useDispatch();

  const [editableRows, setEditableRows] = useState<number[]>([]);
  const [endingBalanceAdjustmentDialogOpen, setEndingBalanceAdjustmentDialogOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<InventoryPerMonthRecord | undefined>(undefined);
  const isManufacturer = useSelector(selectIsManufacturer);
  const [isAdmin, setIsAdmin] = useState(false);
  const { user } = useAuth0();

  useEffect(() => {
    const roles = user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/roles`];
    if (roles?.includes('admin')) {
      setIsAdmin(true);
    }
  }, [user]);

  useEffect(() => {
    setEditableRows(
      storeInventory
        .filter(
          (item) =>
            (item.editable && !item.approvedByUser) ||
            !!editableRows.find((yearAndMonth) => item.yearAndMonth === yearAndMonth),
        )
        .map((item: any) => item.yearAndMonth),
    );
  }, [storeInventory]);

  const canInventoryPerMonthBeUpdated = (inventoryPerMonth: any) => {
    const requiredFields = ['cogs', 'purchasedInventoryCost'];
    isManufacturer && requiredFields.push('suppliesAndMaterialsCost');
    // eslint-disable-next-line no-restricted-syntax
    for (const field of requiredFields) {
      if (!inventoryPerMonth[field] && inventoryPerMonth[field] !== 0) {
        NotificationManager.error(
          'Please provide values for all the mandatory fields. if the correct value is 0, input "0"',
        );
        return false;
      }
    }

    return true;
  };

  const verifyEndOfMonthInventory = (inventoryPerMonth: any) => {
    if (inventoryPerMonth.endInventoryCost >= 0) return true;

    NotificationManager.error('Ending inventory must be positive');
    return false;
  };

  const approveHandler = () => {
    const nextStoreInventory = [...storeInventory];
    const currentEditableRows = [...editableRows];

    for (const [inventoryPerMonthIndex, inventoryPerMonth] of storeInventory.entries()) {
      if (!canInventoryPerMonthBeUpdated(inventoryPerMonth) || !verifyEndOfMonthInventory(inventoryPerMonth)) {
        dispatch(setValidating(true));
        return;
      }

      const inventoryEditableIndex = currentEditableRows.findIndex(
        (yearAndMonth) => yearAndMonth === inventoryPerMonth.yearAndMonth,
      );

      nextStoreInventory.splice(inventoryPerMonthIndex, 1, {
        ...inventoryPerMonth,
        synced: true,
        approvedByUser: true,
      });

      if (inventoryEditableIndex !== -1) {
        currentEditableRows.splice(inventoryEditableIndex, 1);
      }
    }

    dispatch(setInventory(nextStoreInventory));
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    dispatch(updateInventoryAsync(true)).then(() => {
      NotificationManager.success('Data Saved & Synced');
    });
    dispatch(setValidating(false));
    setEditableRows([]);
  };

  const saveHandler = () => {
    const nextStoreInventory = [...storeInventory];

    dispatch(setInventory(nextStoreInventory));
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'then' does not exist on type 'AsyncThunk... Remove this comment to see the full error message
    dispatch(updateInventoryAsync()).then(() => {
      NotificationManager.success('Data Saved');
      dispatch(setValidating(false));
    });
  };

  const openEndingBalanceDialog = (row: InventoryPerMonthRecord): void => {
    setCurrentRow(row);
    setEndingBalanceAdjustmentDialogOpen(true);
  };

  return (
    <MainWrapper>
      <TopTitle />

      <Alert severity="info" sx={{ width: '100%' }}>
        We moved to a new inventory module (and migrated your data as well). Check it out here:{' '}
        <a href="https://home.finaloop.com/inventory">https://home.finaloop.com/inventory</a>
      </Alert>

      <HelpNote />
      <MainCard sx={{ my: 1 }} border={false} content={false}>
        <CardContent sx={{ display: 'flex', py: 1.5, justifyContent: 'space-between', alignItems: 'center' }}>
          <InventoryFilters />
        </CardContent>

        <Grid item>
          <Divider />
        </Grid>

        {/* {!storeInventory.length || storeInventory[0].editable ? (
          <ApproveButtons approveHandler={approveHandler} saveHandler={saveHandler} />
        ) : null} */}

        <InventoryList
          inventory={storeInventory}
          setInventory={(nextInventory: any) => dispatch(setInventory(nextInventory))}
          updateInventoryRecord={(record: any) => dispatch(updateInventoryRecord(record))}
          onCalculatorClick={onCalculatorClick}
          editableRows={editableRows}
          setEditableRows={setEditableRows}
          openEndingBalanceDialog={openEndingBalanceDialog}
        />

        <Alert severity="info" sx={{ width: '100%' }}>
          We moved to a new inventory module (and migrated your data as well). Check it out here:{' '}
          <a href="https://home.finaloop.com/inventory">https://home.finaloop.com/inventory</a>
        </Alert>

        {/* {!storeInventory.length || storeInventory[0].editable ? (
          <ApproveButtons approveHandler={approveHandler} saveHandler={saveHandler} />
        ) : null} */}

        <CogsCalculatorDialog />

        {endingBalanceAdjustmentDialogOpen && (
          <EndingBalanceAdjustmentDialog
            open={endingBalanceAdjustmentDialogOpen}
            onClose={() => setEndingBalanceAdjustmentDialogOpen(false)}
            currentRow={currentRow}
          />
        )}
      </MainCard>

      {/* {isAdmin ? (
        <CardContent sx={{ display: 'flex', py: 1.5, justifyContent: 'space-between', alignItems: 'center' }}>
          <PurchaseInput />
        </CardContent>
      ) : null} */}
    </MainWrapper>
  );
}
