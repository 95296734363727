import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { NotificationManager } from 'react-notifications';
import { getTooltips } from './dashboardAPI';

const callAPIWithLoader = async (callback: any, dispatch: any, ...args: any[]) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  // eslint-disable-next-line no-use-before-define
  dispatch(startLoading());
  try {
    return callback(...args);
  } finally {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    // eslint-disable-next-line no-use-before-define
    dispatch(stopLoading());
  }
};

export const getTooltipsAsync = createAsyncThunk('dashboard/getTooltips', (page, { dispatch }) =>
  callAPIWithLoader(getTooltips, dispatch, page),
);

const initialState = {
  openedModal: null,
  showLoading: 0,
  tooltips: [],
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setOpenedModal: (state, action) => {
      state.openedModal = action.payload;
    },
    startLoading: (state) => {
      state.showLoading += 1;
    },
    stopLoading: (state) => {
      state.showLoading -= 1;
    },
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(builder: any, t: any) => void' is not assig... Remove this comment to see the full error message
  extraReducers: (builder: any, t: any) => {
    builder
      .addCase(getTooltipsAsync.fulfilled, (state: any, action: any) => {
        console.log(action);
        state.tooltips = action.payload.items || [];
      })
      .addMatcher(
        (action: any) => /^dashboard.*rejected?/.test(action.type),
        (_: any, action: any) => {
          NotificationManager.error(action.error.message);
          console.log(action.error);
        },
      )
      .addMatcher(
        (action: any) => /^dashboard\/(store|update|set|delete).*fulfilled?/.test(action.type),
        (_: any, action: any) => {
          NotificationManager.success(action.payload.message);
        },
      );
  },
});

export const { setOpenedModal, startLoading, stopLoading } = dashboardSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectOpenedModal = (state: any) => state.dashboard.openedModal;
export const selectShowLoading = (state: any) => state.dashboard.showLoading;
export const selectTooltips = (state: any) => state.dashboard.tooltips;
export const selectTooltip = (state: any, name: any) => state.dashboard.tooltips.filter((tooltip: any) => tooltip.name === name);

export default dashboardSlice.reducer;
