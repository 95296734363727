import ConfirmationModal from '../../../../components/Modal/ConfirmationModal';
import { setOpenedModal } from '../../../dashboard/dashboardSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUpdatedSales } from '../../salesSlice';
import { isEqual } from 'lodash';

export const SalesPanelClosingDialog = ({ setModalIsClosing, modalIsClosing }: any) => {
  const updatedSales = useSelector(selectUpdatedSales, isEqual);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (modalIsClosing) {
      if (updatedSales.length) {
        setShowConfirmationMessage(true);
      } else {
        dispatch(setOpenedModal(null));
      }
    }
  }, [modalIsClosing, updatedSales, setShowConfirmationMessage, dispatch]);

  return (
    <ConfirmationModal
      open={showConfirmationMessage}
      onCancel={() => {
        setShowConfirmationMessage(false);
        setModalIsClosing(false);
      }}
      onConfirm={() => {
        dispatch(setOpenedModal(null));
        setModalIsClosing(false);
      }}
      cancelText="Stay"
      confirmText="Leave"
    >
      <p>Are you sure you want to leave?</p>

      <p>All unsaved changes will be lost.</p>
    </ConfirmationModal>
  );
};
