export const formatToUSD = (number: any, options = {}) => {
  if (Number.isNaN(parseFloat(number))) {
    return '';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // maximumFractionDigits: 0,
    ...options,
  });

  return formatter.format(number);
};

/**
 *
 * @param {Date} date
 */
export const formatToDateString = (date: any) => {
  const offset = date.getTimezoneOffset();
  try {
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split('T')[0];
  } catch (_) {
    return date;
  }
};

/**
 *
 * @param {Date} date
 * @param {String} format
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
export const getFormattedDate = (date, format) => {
  const formats = {
    dd: date.getDate().toString().padStart(2, 0),
    MM: (date.getMonth() + 1).toString().padStart(2, 0),
    YYYY: date.getFullYear(),
  };

  const delimeter = format.match(/\W/)[0];
  const segments = format.split(delimeter);
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'prev' implicitly has an 'any' type.
  return segments.reduce((prev, curr, index) => prev + (index ? delimeter : '') + formats[curr], '');
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
export const addQueryToURL = (url, query = {}) => {
  if (!Object.keys(query).length) {
    return url;
  }

  if (url.indexOf('?') > -1) {
    url += '&';
  } else {
    url += '?';
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key in query) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (query[key]) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      url += `${key}=${query[key]}&`;
    }
  }

  return url.slice(0, -1);
};

/**
 *
 * @param {Response} response
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
export const handleResponse = (response) => {
  if (!response.ok) {
    throw new Error(response.statusText || 'Something went wrong...');
  }
  return response.json();
};

export const getQueryObject = (): Record<string, any> => {
  const result = {};
  if (window.location.search.length < 4) {
    return result;
  }

  window.location.search
    .substring(1)
    .split('&')
    .forEach((q) => {
      const splitted = q.split('=');
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      // eslint-disable-next-line prefer-destructuring
      result[splitted[0]] = splitted[1];
    });

  return result;
};
