import { Box, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import SalesInputCell from './SalesInputCell';

const OverrideContainer = styled(Box)`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: center;
`;

export default function SalesOverrideAmount({
  row,
  editCellPropsChangeHandler,
  inventoryChannel,
  canUpdateInventorySales,
}: any) {
  const index = row.channelsSales.findIndex((item: any) => item.channel === inventoryChannel.channel);
  const { channel } = inventoryChannel;
  let amount = 0;
  let totalAmount;
  let manualOverriddenAmount;

  if (index !== -1) {
    amount = row.channelsSales[index].amount ?? 0;
    totalAmount = row.channelsSales[index].totalAmount;
    manualOverriddenAmount = row.channelsSales[index].manualOverriddenAmount;
  }

  const onSalesOverrideUpdate = (value: number | null) => {
    const newChannel = {
      channel,
      totalAmount: value,
      amount,
      manualOverriddenAmount: value == null ? value : value - amount,
    };
    const channelsSales = [...row.channelsSales];
    if (index !== -1) {
      channelsSales[index] = newChannel;
    } else {
      channelsSales.push(newChannel);
    }
    editCellPropsChangeHandler(channelsSales, 'channelsSales');
  };

  return (
    <>
      <SalesInputCell
        width="70px"
        style={{ width: '70px' }}
        value={totalAmount}
        onChange={onSalesOverrideUpdate}
        type="number"
        InputProps={{ inputProps: { min: 0 } }}
        disabled={canUpdateInventorySales}
        fake
      />
      {inventoryChannel.channel !== 'Wholesale' &&
      !!amount &&
      (!!manualOverriddenAmount || manualOverriddenAmount === null) ? (
        <OverrideContainer>
          <Typography color="black" variant="caption">
            {amount} in Shopify
          </Typography>
        </OverrideContainer>
      ) : null}
    </>
  );
}
