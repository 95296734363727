import React from 'react';

// material-ui
import { useTheme } from '@mui/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

// constant
const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 },
};

// ===========================|| CUSTOM MAIN CARD ||=========================== //
interface MainCardProps {
  border?: boolean;
  boxShadow?: boolean;
  children?: React.ReactNode;
  content?: boolean;
  contentClass?: string;
  contentSX?: Record<string, any>;
  darkTitle?: boolean;
  secondary?: string | Record<string, any> | React.ReactNode;
  shadow?: string;
  sx?: Record<string, any>;
  className?: string;
  title?: string | Record<string, any> | React.ReactNode;
}

const MainCard = React.forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass,
      contentSX,
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      ...others
    }: MainCardProps,
    _: React.Ref<unknown>,
  ) => {
    const theme = useTheme();
    // @ts-ignore
    const darkShadow =
      (theme as any).palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)';
    const shadowConfig = shadow && boxShadow ? shadow : darkShadow;

    return (
      <Card
        {...others}
        sx={{
          border: border ? '1px solid' : 'none',
          // borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
          borderColor: 'transparent',
          ':hover': {
            boxShadow: boxShadow ? shadowConfig : 'inherit',
          },
          margin: '0 auto',
          ...sx,
        }}
      >
        {/* card header and action */}

        {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
        {darkTitle && title && (
          <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
        )}

        {/* content & header divider */}

        {title && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  },
);

export default MainCard;
