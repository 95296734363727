import Box from '@mui/material/Box';
import FormButton from '../../../../components/Form/FormButton';
import { IconDeviceFloppy, IconRotateClockwise } from '@tabler/icons';
import React from 'react';

import { selectAggregated, selectFilters, selectUpdatedSales, updateSalesAsync } from '../../salesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { updateInventoryRecord } from '../../../inventory/inventorySlice';
import BigNumber from 'bignumber.js';
import { styled, Tooltip } from '@mui/material';
import { isEqual } from 'lodash';

const SubmitButton = styled(FormButton)`
  background-color: #56bbd6;
  font-size: 12px;
  text-transform: none;
  &:hover {
      background-color: rgb(86, 187, 214, 0.7);
      border-color: #56bbd6;
      box-shadow: none;
  },
`;

const RightSubmitButton = styled(SubmitButton)`
  margin-left: 16px;
`;

export const SyncButtons = ({ setModalIsClosing, setOpenedModal }: any) => {
  const storeAggregated = useSelector(selectAggregated, isEqual);
  const updatedSales = useSelector(selectUpdatedSales, isEqual);
  const salesFilters = useSelector(selectFilters, isEqual);
  const dispatch = useDispatch();

  const updateHandler = (closeSalesPanel: boolean = false) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'then' does not exist on type 'AsyncThunk... Remove this comment to see the full error message
    dispatch(updateSalesAsync()).then((res: any) => {
      if (res.type.indexOf('/fulfilled') > -1 && closeSalesPanel) {
        dispatch(
          updateInventoryRecord({
            yearAndMonth: salesFilters.year * 100 + salesFilters.month,
            deadInventoryCost: parseFloat(new BigNumber(storeAggregated.deadInventorySum).toFixed(2)),
            giveawayInventoryCost: parseFloat(new BigNumber(storeAggregated.giveawaysSum).toFixed(2)),
            cogs: parseFloat(new BigNumber(storeAggregated.cogs).toFixed(2)),
          }),
        );

        dispatch(setOpenedModal(null));
      }
    });
  };

  return (
    <Box display="flex" fontWeight={500} justifyContent="flex-end" alignItems="center">
      <Tooltip title="Save your changes for later">
        <div>
          <SubmitButton
            sx={{ mr: 2, fontSize: '12px' }}
            color="primary"
            variant="contained"
            size="normal"
            startIcon={<IconDeviceFloppy />}
            onClick={() => updateHandler()}
            disabled={!updatedSales.length}
          >
            Save
          </SubmitButton>
        </div>
      </Tooltip>

      <Tooltip title="This will sync your COGS & other transactions to your Monthly Inventory Tracker">
        <div>
          <RightSubmitButton
            color="primary"
            variant="contained"
            size="normal"
            startIcon={<IconRotateClockwise />}
            onClick={() => updateHandler(true)}
            disabled={!updatedSales.length}
            sx={{ textTransform: 'none', fontSize: '12px' }}
          >
            Sync to Tracker
          </RightSubmitButton>
        </div>
      </Tooltip>
    </Box>
  );
};
