import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Slide,
  Switch,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { EndingBalanceUpdate, InventoryPerMonthRecord } from '../../../../types';
import InputCell from '../../../../components/Form/InputCell';
import { formatToUSD } from '../../../../utils/helpers';
import FormControl from '@mui/material/FormControl';
import { monthsList } from '../../../../utils/months';
import Select from '../../../../components/Form/Select';
import { useDispatch } from 'react-redux';
import { updateEndingBalanceAdjustment } from '../../inventorySlice';
import FormButton from '../../../../components/Form/FormButton';
import BigNumber from 'bignumber.js';

// @ts-ignore
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

interface EndingBalanceAdjustmentDialogProps {
  open: boolean;
  onClose: () => void;
  currentRow: InventoryPerMonthRecord | undefined;
}

const currentMonth = new Date().getUTCMonth();

const initialState: EndingBalanceUpdate = {
  amount: 0,
  spread: false,
  fromMonth: 0,
  toMonth: currentMonth,
};

const calculateMonthlyAmount = ({ amount, fromMonth, toMonth }: EndingBalanceUpdate): number => {
  return (amount && parseFloat(new BigNumber(amount).div(1 + toMonth - fromMonth).toFixed(2))) || 0;
};

export function EndingBalanceAdjustmentDialog({ open, onClose, currentRow }: EndingBalanceAdjustmentDialogProps) {
  const [formData, setFormData] = useState(initialState);
  const allMonths = useRef(monthsList);
  const [months, setMonths] = useState(allMonths.current);
  const [maxMonth, setMaxMonth] = useState(currentMonth);
  const [monthlyAmount, setMonthlyAmount] = useState<number>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentRow) {
      setMaxMonth(currentRow.month - 1);
      setFormData((currentFormData) => ({
        ...currentFormData,
        toMonth: currentRow.month - 1,
        amount: currentRow?.endingBalanceAdjustments ?? 0,
      }));
    }
  }, [currentRow]);

  useEffect(() => {
    setMonthlyAmount(calculateMonthlyAmount(formData));
  }, [formData]);

  const handleSubmitClose = () => {
    formData.spread
      ? dispatch(
          updateEndingBalanceAdjustment({
            spread: formData.spread,
            amount: `${formData.amount}` === '' ? undefined : formData.amount,
            fromMonth: formData.fromMonth + 1,
            toMonth: formData.toMonth + 1,
          }),
        )
      : dispatch(
          updateEndingBalanceAdjustment({ ...formData, fromMonth: currentRow!.month, toMonth: currentRow!.month }),
        );
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open} TransitionComponent={Transition}>
      <DialogTitle>Adjustments for Inaccuracies</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Box display="flex" flexDirection="column" gap={2} py={4}>
            <FormControl>
              <InputCell
                value={formData.amount}
                format
                onChange={(e: any) => {
                  setFormData({ ...formData, amount: e.target.value });
                }}
                type="number"
                label="Adjustment amount"
                width="100%"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {!formData.spread && (
                <Typography variant="body1" color="black" maxWidth={300} mt={1}>
                  Amount will adjust your current month's ending inventory.
                </Typography>
              )}
            </FormControl>
            <FormControl>
              <FormGroup>
                {maxMonth !== 0 && !formData.spread && (
                  <Typography variant="body1" color="black" maxWidth={300}>
                    You can also spread the adjustment across multiple months, by selecting the toggle.
                  </Typography>
                )}
                {maxMonth !== 0 && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.spread}
                        onChange={() => setFormData({ ...formData, spread: !formData.spread })}
                        name="spread"
                      />
                    }
                    sx={{ color: 'black' }}
                    label="Spread"
                  />
                )}
              </FormGroup>
            </FormControl>
            {formData.spread && (
              <FormControl>
                <FormGroup row>
                  <Select
                    sx={{ minWidth: 150, mr: 2 }}
                    options={months.slice(0, formData.toMonth + 1)}
                    value={months[formData.fromMonth]}
                    getOptionLabel={(option: any) => option.toString()}
                    isOptionEqualToValue={(option: any, value: any) => option === value}
                    onChange={(_: any, value: any) =>
                      setFormData({ ...formData, fromMonth: months.findIndex((month) => value === month) })
                    }
                    label="From"
                  />
                  <Select
                    sx={{ minWidth: 150 }}
                    options={months.slice(formData.fromMonth, maxMonth + 1)}
                    value={months[formData.toMonth]}
                    getOptionLabel={(option: any) => option.toString()}
                    isOptionEqualToValue={(option: any, value: any) => option === value}
                    onChange={(_: any, value: any) =>
                      setFormData({ ...formData, toMonth: months.findIndex((month) => value === month) })
                    }
                    label="To"
                  />
                </FormGroup>
                <Typography variant="body1" color="black" maxWidth={300} mt={2}>
                  <b>Note</b>: Spreading amounts evenly across specific months will add ${monthlyAmount} to any existing
                  adjustments between {months[formData.fromMonth]} to {months[formData.toMonth]}.
                </Typography>
              </FormControl>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <FormButton onClick={onClose} secondary color="primary" variant="contained">
          Cancel
        </FormButton>

        <FormButton onClick={handleSubmitClose} color="primary" variant="contained">
          Confirm
        </FormButton>
      </DialogActions>
    </Dialog>
  );
}
