import { Box, styled, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import InfoTooltip from '../../../components/InfoTooltip/InfoTooltip';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectChannels } from '../../products/productsSlice';
import { ChangeProduct, SyncPurchasePrices } from '../SalesPanel/Components';
import { isEqual } from 'lodash';

const SalesCell = styled(TableCell)`
  margin-top: 0;
  border: 0;
  position: relative;
  color: black !important;
`;

export const SalesListHead = () => {
  const storeChannels = useSelector(selectChannels, isEqual);

  return (
    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
      <TableRow sx={{ border: '0 !important' }}>
        <SalesCell align="left">
          <ChangeProduct />
        </SalesCell>

        <SalesCell align="center" />

        <SalesCell align="center" />

        <SalesCell align="center" sx={{ borderRight: '1px dashed #ddd', padding: 0, position: 'relative' }}>
          <SyncPurchasePrices />
        </SalesCell>

        <SalesCell align="center" colSpan={storeChannels.length + 1} sx={{ borderRight: '1px dashed #ddd' }}>
          <Typography sx={{ fontWeight: 'bold' }}>COGS</Typography>
        </SalesCell>

        <SalesCell align="center" colSpan={3}>
          <Typography sx={{ fontWeight: 'bold' }}>Others</Typography>
        </SalesCell>
      </TableRow>

      <TableRow>
        <SalesCell sx={{ minWidth: '300px', pl: 7 }} align="left">
          <div>Product</div>
        </SalesCell>

        <SalesCell align="center">SKU</SalesCell>

        <SalesCell align="center">Source</SalesCell>

        <SalesCell align="center" sx={{ borderRight: '1px dashed #ddd', width: '150px' }}>
          <WrapperBox>
            <div>
              Cost <br /> per Unit
            </div>
            <FloatingInfoTooltip name="COGS Calculator - Cost per Unit" />
          </WrapperBox>
        </SalesCell>

        {storeChannels.map((inventoryChannel: any) => (
          <SalesCell width="170px" key={inventoryChannel.channel} align="center" className="channel">
            <WrapperBox>
              {inventoryChannel.image ? (
                <>
                  <div>
                    Shopify <br />
                    Units Sold
                  </div>
                  <FloatingInfoTooltip name="COGS Calculator - Shopify Units Sold" />
                </>
              ) : (
                <>
                  <div>{inventoryChannel.channel} Units Sold</div>
                  <FloatingInfoTooltip name="COGS Calculator - Wholesale Units Sold" />
                </>
              )}
            </WrapperBox>
          </SalesCell>
        ))}

        <SalesCell width="150px" align="center" sx={{ borderRight: '1px dashed #ddd' }}>
          <div>COGS (US$)</div>
        </SalesCell>

        <SalesCell width="190px" align="center">
          <WrapperBox>
            <div>
              Giveaway <br />
              Units
            </div>
            <FloatingInfoTooltip name="COGS Calculator - Giveaway Units" />
          </WrapperBox>
        </SalesCell>

        <SalesCell width="190px" align="center">
          <WrapperBox>
            <div>Dead Inventory Units</div>
            <FloatingInfoTooltip name="COGS Calculator - Dead Inventory Units" />
          </WrapperBox>
        </SalesCell>
      </TableRow>
    </TableHead>
  );
};

const FloatingInfoTooltip = styled(InfoTooltip)({
  position: 'absolute',
  right: '10px',
});

const WrapperBox = ({ children, maxWidth }: { children: JSX.Element[] | JSX.Element; maxWidth?: string }) => (
  <Box display="flex" maxWidth={maxWidth || '100px'} margin="auto" alignItems="center" justifyContent="center">
    {children}
  </Box>
);
