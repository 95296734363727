import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchCompany, updateCompany } from './settingsAPI';
import { setIsManufacturer as setInventoryIsManufacturer } from '../inventory/inventorySlice';

const initialState = {
  isOpen: false,
  company: undefined,
};

export const getCompany = createAsyncThunk('settings/company', async (_, { dispatch, getState }) => {
  const response = await fetchCompany();
  dispatch(setInventoryIsManufacturer(response.data.isManufacturer));
  return response.data;
});

export const setIsManufacturer = createAsyncThunk(
  'settings/isManufacturer',
  async (isManufacturer: boolean, { dispatch }) => {
    const response = await updateCompany({ isManufacturer });
    dispatch(setInventoryIsManufacturer(isManufacturer));
    return response.data;
  },
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.fulfilled, (state, action) => {
        state.company = action.payload;
      })
      .addCase(setIsManufacturer.fulfilled, (state, action) => {
        state.company = action.payload;
      });
  },
});

export const { setOpen } = settingsSlice.actions;
export const selectSettingsIsOpen = (state: any) => state.settings.isOpen;
export const selectIsManufacturer = (state: any) => state.settings.company?.isManufacturer ?? false;
export const selectCompanyName = (state: any) => state.settings.company?.name ?? false;

export default settingsSlice.reducer;
