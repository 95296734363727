import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import SalesList from '../SalesList/SalesList';
import SalesFilters from '../SalesFilters/SalesFilters';
import { selectChannels } from '../../products/productsSlice';
import { setOpenedModal } from 'views/dashboard/dashboardSlice';
import MixpanelContext from 'services/MixpanelContext';
import { styled } from '@mui/material';
import { SalesNote } from './Components/SalesNote';
import { SyncButtons } from './Components/SyncButtons';
import { SalesPanelClosingDialog } from './Components/SalesPanelClosingDialog';
import { getSalesAsync } from '../salesSlice';
import { isEqual } from 'lodash';
import { TopTitle } from './Components/TopTitle';

export default function SalesPanel({ modalIsClosing, setModalIsClosing }: any) {
  const mixpanel = useContext(MixpanelContext);
  const storeChannels = useSelector(selectChannels, isEqual);
  const dispatch = useDispatch();

  const SalesWrapper = styled('div')({
    padding: 20,
  });

  useEffect(() => {
    if (storeChannels.length) {
      mixpanel.identify(storeChannels[0].owner);
      mixpanel.track('user loaded inventory calculator COGS page', {
        Page: 'sales',
      });
    }
  }, [mixpanel, storeChannels]);

  useEffect(() => {
    dispatch(getSalesAsync());
  }, [dispatch]);

  return (
    <SalesWrapper>
      <TopTitle />

      <SalesNote />

      <SalesFilters />

      <Box my={2}>
        <SyncButtons setModalIsClosing={setModalIsClosing} setOpenedModal={setOpenedModal} />
      </Box>

      <SalesList />

      <SyncButtons setModalIsClosing={setModalIsClosing} setOpenedModal={setOpenedModal} />

      <SalesPanelClosingDialog setModalIsClosing={setModalIsClosing} modalIsClosing={modalIsClosing} />
    </SalesWrapper>
  );
}
