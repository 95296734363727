import { styled, TooltipProps } from '@mui/material';
import { Tooltip, tooltipClasses } from '@mui/material';
import React from 'react';

const StyledTooltip = ({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />;

const HtmlTooltip = styled(StyledTooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: (props: any) => props.mw || 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default HtmlTooltip;
