import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

if (process.env.REACT_APP_LOGROCKET_ID) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);

  setupLogRocketReact(LogRocket);
}

export { LogRocket };
