import Typography from '@mui/material/Typography';
import { Box, TextField } from '@mui/material';
// @ts-ignore
import styles from './ProductInfo.module.css';
import { useContext, useEffect, useState } from 'react';
import FormButton from 'components/Form/FormButton';
import { makeStyles } from '@mui/styles';
import MixpanelContext from 'services/MixpanelContext';
import { formatToDateString, formatToUSD } from 'utils/helpers';
import InputCell from 'components/Form/InputCell';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useSelector } from 'react-redux';
import { selectChannels } from '../productsSlice';
import { isEqual } from 'lodash';

const useStyles = makeStyles((theme) => ({
  input: {
    '& fieldset': {
      borderRadius: '2px',
    },
    '& input': {
      borderRadius: '2px',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'typography' does not exist on type 'Defa... Remove this comment to see the full error message
      ...theme.typography.input,
    },
  },
  formControl: {
    '&.has-error': {
      position: 'relative',
      paddingBottom: '18px',

      '& .error-message': {
        position: 'absolute',
        fontSize: '12px',
        left: 0,
        bottom: 0,
        width: '100%',
        color: 'red',
      },
    },
  },

  dateInput: {
    '& fieldset': {
      borderRadius: '2px',
    },
    '& label': {
      top: '-3px',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      fontSize: theme.spacing(1.5),
    },
    '& input': {
      borderRadius: '2px',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      fontSize: theme.spacing(1.5),
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      padding: theme.spacing(1.5),
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      borderColor: theme.palette.grey[200],
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
  },
  hidden: {
    fontWeight: 'bold',
    display: 'none',
  },
}));

function ProductInfo({ product, onSubmit, onCancel }: any) {
  const [productData, setProductData] = useState({
    sku: '',
    name: '',
    description: '',
    purchasePrice: '',
    image: '',
    effectiveDate: null,
  });

  const [changed, setChanged] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [maxDate, setMaxDate] = useState(new Date());

  const storeChannels = useSelector(selectChannels, isEqual);
  const mixpanel = useContext(MixpanelContext);

  const classes = useStyles();

  useEffect(() => {
    if (product) {
      const manualPrice = product.purchasePrices[0];
      setMaxDate(new Date(manualPrice.effectiveFromMs));
      setProductData({
        ...product,
        purchasePrice: manualPrice.price,
        effectiveDate: new Date(manualPrice.effectiveFromMs),
      });
    }
  }, [product]);

  useEffect(() => {
    if (!product) {
      setChanged(true);
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key in productData) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (productData[key] !== product[key]) {
        setChanged(true);
        return;
      }
    }

    setChanged(false);
  }, [productData]);

  useEffect(() => {
    if (storeChannels.length) {
      mixpanel.track('add new product');
    }
  }, [mixpanel, storeChannels]);

  useEffect(() => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setIsReady(productData.name && productData.sku && productData.purchasePrice);
  }, [productData.name, productData.sku, productData.purchasePrice]);

  const inputChangeHandler = (key: any, e: any) => {
    const nextProductData = { ...productData };
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    nextProductData[key] = e.target.value;
    if (key === 'purchasePrice') {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      nextProductData[key] = parseFloat(e.target.value);
      if (product) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (nextProductData[key] === product[key]) {
          nextProductData.effectiveDate = null;
        } else {
          nextProductData.effectiveDate = nextProductData.effectiveDate || formatToDateString(new Date());
        }
      }
    }
    setProductData(nextProductData);
  };

  const shouldDateBeVisible = () => true;

  const submitHandler = () => {
    const formData = new FormData();
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in productData) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      formData.append(key, productData[key]);
    }
    onSubmit(formData, productData);
  };

  return (
    <div className={styles.product}>
      <div className={styles.inputs}>
        <Typography variant="h5">Product Details</Typography>

        <TextField
          className={classes.input}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"myColors"' is not assignable to type '"erro... Remove this comment to see the full error message
          color="myColors"
          onChange={(e) => inputChangeHandler('name', e)}
          value={productData.name || ''}
          inputProps={{ style: { fontSize: 14 } }}
          InputLabelProps={{ style: { fontSize: 14 } }}
          size="small"
          id="name"
          label="Product name*"
          variant="outlined"
        />

        <TextField
          className={classes.input}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"myColors"' is not assignable to type '"erro... Remove this comment to see the full error message
          color="myColors"
          onChange={(e) => inputChangeHandler('sku', e)}
          value={productData.sku || ''}
          inputProps={{ style: { fontSize: 14 } }}
          InputLabelProps={{ style: { fontSize: 14 } }}
          size="small"
          id="sku"
          label="SKU*"
          variant="outlined"
        />

        <Box className={[classes.formControl, 'has-error'].join(' ')}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              renderInput={(props: any) => (
                <TextField
                  className={[classes.dateInput, shouldDateBeVisible() ? '' : classes.hidden].join(' ')}
                  fullWidth
                  {...props}
                  helperText=""
                />
              )}
              label="Initially introduced:"
              value={productData.effectiveDate}
              mask="__/__/____"
              inputFormat="MM/dd/yyyy"
              maxDate={maxDate}
              onChange={(newDate: any) =>
                inputChangeHandler('effectiveDate', { target: { value: formatToDateString(newDate) } })
              }
            />
          </LocalizationProvider>

          <Box className="error-message">
            {new Date(productData.effectiveDate!).getTime() > Date.now()
              ? 'Please provide current or historical date'
              : null}
          </Box>
        </Box>

        <TextField
          className={classes.input}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"myColors"' is not assignable to type '"erro... Remove this comment to see the full error message
          color="myColors"
          onChange={(e) => inputChangeHandler('description', e)}
          value={productData.description || ''}
          inputProps={{ style: { fontSize: 14 } }}
          InputLabelProps={{ style: { fontSize: 14 } }}
          size="small"
          id="Description"
          label="description"
          multiline
          rows={4}
          variant="outlined"
        />
        {!product && (
          <InputCell
            // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'true' since the... Remove this comment to see the full error message
            value={!productData.purchasePrice && productData.purchasePrice !== 0 ? '' : productData.purchasePrice}
            format
            onChange={(e: any) => inputChangeHandler('purchasePrice', e)}
            type="number"
            variant="outlined"
            label="Cost price per unit*"
            className={classes.input}
            width="100%"
          />
        )}
      </div>

      <Box display="flex" justifyContent="flex-end" gap={2}>
        <FormButton onClick={onCancel} secondary color="primary" variant="contained">
          Cancel
        </FormButton>

        <FormButton disabled={!changed || !isReady} onClick={submitHandler} color="primary" variant="contained">
          {product ? 'Update' : 'Create'}
        </FormButton>
      </Box>
    </div>
  );
}

export default ProductInfo;
