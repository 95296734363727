import SalesDialog from '../../sales/SalesDialog';
import React from 'react';
import { Dialog, Slide, styled } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useSelector } from 'react-redux';
import { selectOpenedModal } from '../../dashboard/dashboardSlice';

const StyledCogsCalculatorDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    padding: '0px',
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CogsCalculatorDialog = () => {
  const openedModal = useSelector(selectOpenedModal);

  return (
    <StyledCogsCalculatorDialog fullScreen open={!!openedModal} TransitionComponent={Transition}>
      <div>{openedModal ? <SalesDialog /> : null}</div>
    </StyledCogsCalculatorDialog>
  );
};
