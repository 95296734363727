import { Tooltip } from '@mui/material';
import FormButton from '../../../../components/Form/FormButton';
import { IconPlus } from '@tabler/icons';
import React from 'react';
import CustomModal from '../../../../components/Modal/Modal';
import ProductInfo from '../../../products/ProductInfo/ProductInfo';
import {
  selectProductInfoModal,
  setShowProductInfoModal,
  storeProductAsync,
  updateProductAsync,
} from '../../../products/productsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getSaleAsyncWithPolling, selectFilters } from '../../salesSlice';
import { selectCanUpdateInventorySales } from '../../../inventory/inventorySlice';

export const ChangeProduct = () => {
  const dispatch = useDispatch();

  const { year, month } = useSelector(selectFilters);
  const { open, product } = useSelector(selectProductInfoModal);
  const canUpdateInventorySales = useSelector(selectCanUpdateInventorySales);

  const productSubmitHandler = (formData: any, productData: any) => {
    const nextProduct = {
      ...product,
      ...productData,
      image:
        typeof productData.image === 'string' || productData.image === null
          ? productData.image
          : URL.createObjectURL(productData.image),
    };

    if (product) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      dispatch(updateProductAsync(nextProduct)).then(() => {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        dispatch(getSaleAsyncWithPolling({ yearAndMonthAndProductId: `${year}-${month}-${nextProduct.id}` }));
      });
    } else {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      dispatch(storeProductAsync(productData))
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'unwrap' does not exist on type 'AsyncThu... Remove this comment to see the full error message
        .unwrap()
        .then((result: any) => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          dispatch(getSaleAsyncWithPolling({ yearAndMonthAndProductId: `${year}-${month}-${result.data.id}` }));
        });
    }

    dispatch(setShowProductInfoModal({ open: false }));
  };

  const productCancelHandler = () => {
    dispatch(setShowProductInfoModal({ open: false }));
  };

  return (
    <Tooltip title="SKUs that you may sell or give away, but are not listed on your online stores.">
      <div>
        <FormButton
          sx={{ padding: '5px 10px', fontSize: '12px' }}
          color="primary"
          variant="contained"
          size="large"
          startIcon={<IconPlus size="15px" />}
          onClick={() => dispatch(setShowProductInfoModal({ open: true }))}
          disabled={canUpdateInventorySales}
        >
          Add Product
        </FormButton>

        <CustomModal open={open}>
          <ProductInfo product={product} onSubmit={productSubmitHandler} onCancel={productCancelHandler} />
        </CustomModal>
      </div>
    </Tooltip>
  );
};
