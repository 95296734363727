import React, { useEffect, useState } from 'react';
import { Drawer, Grid, Stack, styled, Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsManufacturer, selectSettingsIsOpen, setIsManufacturer, setOpen } from './settingsSlice';
import SubCard from '../../components/cards/SubCard';
import InfoTooltip from '../../components/InfoTooltip/InfoTooltip';
import { updateInventoryAsync, updateInventoryFromStartOfYear } from '../inventory/inventorySlice';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';

const SettingsBar = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectSettingsIsOpen);
  const isManufacturer = useSelector(selectIsManufacturer);
  const [isManufacturerEnabled, setIsManufacturerEnabled] = useState(isManufacturer);
  const [showIsResellerDialog, setShowIsResellerDialog] = useState(false);

  const drawerToggle = () => {
    dispatch(setOpen(false));
  };

  const updateManufacturer = () => {
    setIsManufacturerEnabled(!isManufacturerEnabled);
    // @ts-ignore
    dispatch(setIsManufacturer(!isManufacturerEnabled)).then(() => {
      dispatch(updateInventoryFromStartOfYear());
      dispatch(updateInventoryAsync());
    });
  };

  const onToggleClicked = () => {
    if (isManufacturerEnabled) {
      setShowIsResellerDialog(true);
    } else {
      updateManufacturer();
    }
  };

  useEffect(() => {
    setIsManufacturerEnabled(isManufacturer);
  }, [isManufacturer]);

  const FloatingInfoTooltip = styled(InfoTooltip)`
    position: absolute;
    right: 10px;
    top: 40px;
  `;

  const drawer = (
    <>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12} position="relative">
          <FloatingInfoTooltip name="I want to track Supplies & Materials separately toggle" />
          <SubCard title="I want to track Supplies & Materials separately from Inventory Ready for Sale">
            <Grid item xs={12} container spacing={2} alignItems="center">
              <Grid item>
                <Stack spacing={2}>
                  <Switch checked={isManufacturerEnabled} onChange={onToggleClicked} />
                </Stack>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
      </Grid>
      <ConfirmationModal
        open={showIsResellerDialog}
        onCancel={() => {
          setShowIsResellerDialog(false);
        }}
        onConfirm={() => {
          setShowIsResellerDialog(false);
          updateManufacturer();
        }}
        cancelText="Cancel"
        confirmText="Confirm"
      >
        <p>This will also remove your Supplies & Materials ending balance inputs </p>
      </ConfirmationModal>
    </>
  );

  return (
    <Drawer
      anchor="right"
      onClose={drawerToggle}
      open={isOpen}
      PaperProps={{
        sx: {
          width: 280,
        },
      }}
    >
      {drawer}
    </Drawer>
  );
};

export default SettingsBar;
