import {styled} from "@mui/styles";
import {TableRow} from "@mui/material";

export const InventoryTableRow =  styled(TableRow)(({ theme }) => ({
    border: '1px solid',
    // @ts-ignore
    borderColor: theme.palette.secondary.lighter,
    borderBottom: '10px solid',
    // @ts-ignore
    borderBottomColor: theme.palette.primary.light,
    // @ts-ignore
    backgroundColor: theme.palette.background.paper,
}));

