import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

export const useRedirectToNewInventoryApp = () => {
  const client = useLDClient();
  const { user } = useAuth0();

  useEffect(() => {
    if (!user || !client) return;
    client.identify({
      key: user?.sub,
      email: user?.email,
      name: user?.name,
      custom: {
        companyId: user?.companyId,
        createdAt: user?.createdAt,
      },
    });
  }, [user, client]);

  const shouldOldInventoryTrackerRedirectToNewOne =
    client?.variation('should-old-inventory-tracker-redirect-to-new-one') || false;

  return { shouldOldInventoryTrackerRedirectToNewOne };
};
