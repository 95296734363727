import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableContainer } from '@mui/material';
import { useDispatch } from 'react-redux';
import InventoryListRow from './InventoryListRow';
import InventorySummaryListRow from './InventorySummaryListRow';
import { InventoryListHead } from './InventoryListHead';
import { NoDataTableContent } from './NoDataTableContent';

const useStyles = makeStyles((theme) => ({
  root: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
    backgroundColor: theme.palette.primary.light,
  },
  table: {
    width: 'calc(100% - 1px)',
    minWidth: 350,
    color: 'black',
    '& thead tr': {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      backgroundColor: theme.palette.primary.light,
      border: 'none',
      boxSizing: 'border-box',

      '& th': {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        color: theme.palette.grey[500],
        backgroundColor: 'initial',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
        [theme.breakpoints.down('lg')]: {
          fontSize: '12px',
        },
      },

      '& svg': {
        stroke: 'white',
        verticalAlign: 'middle',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        fill: theme.palette.primary[900],
      },

      '& th:first-child': {
        paddingLeft: '10px',
      },
    },
    '& input': {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'typography' does not exist on type 'Defa... Remove this comment to see the full error message
      ...theme.typography.input,
    },
  },
}));

export default function InventoryList({
  inventory,
  setInventory,
  editableRows,
  setEditableRows,
  updateInventoryRecord,
  openEndingBalanceDialog,
}: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const editCellPropsChangeHandler = (e: any, row: any, field: any, canBeNegative?: boolean) => {
    const val = e.target.value;
    const updatedRecord = {
      ...row,
      [field]: Number.isNaN(parseFloat(val)) ? undefined : canBeNegative ? parseFloat(val) : Math.abs(parseFloat(val)),
    };
    updateInventoryRecord(updatedRecord);
  };

  const editHandler = (yearAndMonth: any) => {
    if (!editableRows.includes(yearAndMonth)) {
      // Recreates the inventory and sets is approved to false
      const inventoryIndex = inventory.findIndex((inv: any) => inv.yearAndMonth === yearAndMonth);
      const nextInventory = [...inventory];
      nextInventory[inventoryIndex] = { ...nextInventory[inventoryIndex] };
      dispatch(setInventory(nextInventory));
      setEditableRows([...editableRows, yearAndMonth]);
    }
  };

  const removeEditRow = (yearAndMonth: any) => {
    const index = editableRows.findIndex((editableRow: any) => editableRow === yearAndMonth);
    setEditableRows([...editableRows.slice(0, index), ...editableRows.slice(index + 1)]);
  };

  const lastUnchangedMonthIndex = useMemo(
    () => inventory.findIndex(({ approvedByUser }: any) => !approvedByUser),
    [inventory],
  );

  const displayedInventory = useMemo(() => inventory, [inventory]);

  return (
    <TableContainer className={classes.root}>
      <Table stickyHeader className={classes.table}>
        <InventoryListHead />

        <TableBody>
          <InventorySummaryListRow />
          {displayedInventory.length
            ? displayedInventory.map((row: any, index: any) => (
                <InventoryListRow
                  key={row.yearAndMonth}
                  row={row}
                  editCellPropsChangeHandler={editCellPropsChangeHandler}
                  editableRows={editableRows}
                  index={index}
                  editHandler={editHandler}
                  lastUnchangedMonthIndex={lastUnchangedMonthIndex}
                  removeEditRow={removeEditRow}
                  openEndingBalanceDialog={openEndingBalanceDialog}
                  isLast={index === displayedInventory.length - 1}
                />
              ))
            : null}
        </TableBody>
      </Table>
      {!displayedInventory.length ? <NoDataTableContent /> : null}
    </TableContainer>
  );
}
