import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {startLoading, stopLoading} from '../dashboard/dashboardSlice';
import {fetchInventory, updateInventory, updateInventoryPurchase} from './inventoryAPI';
import {createSelector} from 'reselect';
import {EndingBalanceUpdate, InventoryPerMonthRecord, InventoryState} from '../../types';
import BigNumber from 'bignumber.js';

const initialState: InventoryState = {
  data: [],
  availableYears: [2022],
  filters: {
    year: 2022,
  },
  monthlyInventory: undefined,
  validating: false,
  isManufacturer: false,
};

const callAPIWithLoader = async (callback: any, dispatch: any, ...args: any[]) => {
  dispatch(startLoading({}));
  try {
    const response = await callback(...args);
    return response;
  } finally {
    dispatch(stopLoading({}));
  }
};

export const getInventoryAsync = createAsyncThunk('inventory/getInventory', (_, { dispatch, getState }) =>
  callAPIWithLoader(fetchInventory, dispatch),
);

export const updateInventoryAsync = createAsyncThunk('inventory/updateInventory', (sync, { dispatch, getState }) =>
  callAPIWithLoader(updateInventory, dispatch, {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    updated: getState().inventory.data,
    sync,
  }),
);

export const updateInventoryPurchaseAsync = createAsyncThunk(
  'inventory/updatePurchaseInventory',
  (updatedInventory, { dispatch, getState }) => {
    return callAPIWithLoader(updateInventoryPurchase, dispatch, updatedInventory);
  },
);

const getNumberOrZero = (candidate: number | undefined) => candidate || 0;
const updateInventoryMonthlyEndingCost = (
  record: InventoryPerMonthRecord,
  initialInventoryCost: number,
  initialSuppliesAndMaterialsCost: number | undefined,
  isManufacturer: boolean,
): InventoryPerMonthRecord => {
  const {
    purchasedInventoryCost,
    cogs,
    giveawayInventoryCost,
    deadInventoryCost,
    endingBalanceAdjustments,
    suppliesAndMaterialsCost: prevSuppliesAndMaterialsCost,
  } = record;

  const suppliesAndMaterialsCost = isManufacturer ? prevSuppliesAndMaterialsCost : 0;
  const endInventoryCost =
    getNumberOrZero(initialInventoryCost) +
    getNumberOrZero(initialSuppliesAndMaterialsCost) +
    getNumberOrZero(purchasedInventoryCost) -
    getNumberOrZero(cogs) -
    getNumberOrZero(deadInventoryCost) -
    getNumberOrZero(giveawayInventoryCost) +
    getNumberOrZero(endingBalanceAdjustments) -
    getNumberOrZero(suppliesAndMaterialsCost);
  const finishedGoodsCost = isManufacturer ? endInventoryCost : 0;
  return {
    ...record,
    initialInventoryCost,
    endInventoryCost,
    initialSuppliesAndMaterialsCost,
    synced: false,
    finishedGoodsCost,
    suppliesAndMaterialsCost,
  };
};

const recalculateInventoryFromIndex = (
  inventory: InventoryPerMonthRecord[],
  fromIndex: number,
  isManufacturer: boolean,
) => {
  const nextInventory = inventory.reverse();
  const currentIndex = inventory.length - 1 - fromIndex;

  // Recalculate the end of the month balances onwards
  let prevEndingInventoryCost = nextInventory[currentIndex].initialInventoryCost;
  let prevSuppliesAndMaterialsCost = nextInventory[currentIndex].initialSuppliesAndMaterialsCost;
  const recalculatedRecords = nextInventory.splice(currentIndex).map((record) => {
    const updatedRecord = updateInventoryMonthlyEndingCost(
      record,
      prevEndingInventoryCost,
      prevSuppliesAndMaterialsCost,
      isManufacturer,
    );
    prevEndingInventoryCost = updatedRecord.endInventoryCost;
    prevSuppliesAndMaterialsCost = updatedRecord.suppliesAndMaterialsCost;
    return updatedRecord;
  });

  return [...nextInventory, ...recalculatedRecords].reverse();
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setInventory: (state, action: PayloadAction<InventoryPerMonthRecord[]>) => {
      state.data = state.data.map(
        (inventoryRecord) =>
          action.payload.find(({ yearAndMonth }) => inventoryRecord.yearAndMonth === yearAndMonth) || inventoryRecord,
      );
    },
    updateInventoryRecord: (state, action) => {
      const nextInventory = [...state.data];
      const currentIndex = nextInventory.findIndex((record) => record.yearAndMonth === action.payload.yearAndMonth);
      nextInventory[currentIndex] = { ...nextInventory[currentIndex], ...action.payload, synced: false };

      state.data = recalculateInventoryFromIndex(nextInventory, currentIndex, state.isManufacturer);
    },
    updateEndingBalanceAdjustment: (state, action: PayloadAction<EndingBalanceUpdate>) => {
      const { toMonth, fromMonth, amount, spread } = action.payload;

      const fromMonthIndex = state.data.findIndex(({ month }) => month === fromMonth);
      const toMonthIndex = state.data.findIndex(({ month }) => month === toMonth);
      const spreadAmount = amount && parseFloat(new BigNumber(amount).div(1 + toMonth - fromMonth).toFixed(2));

      const nextInventory = state.data.map((inventoryPerMonth, index) => {
        // TODO: is from month index larger then to month index?
        if (index >= toMonthIndex && index <= fromMonthIndex) {
          const endingBalanceAdjustments =
            index !== toMonthIndex
              ? (inventoryPerMonth.endingBalanceAdjustments ?? 0) + (spreadAmount || 0)
              : spreadAmount;
          return { ...inventoryPerMonth, endingBalanceAdjustments };
        }

        return inventoryPerMonth;
      });

      state.data = recalculateInventoryFromIndex(nextInventory, fromMonthIndex, state.isManufacturer);
    },
    updateInventoryFromStartOfYear: (state: InventoryState) => {
      const currentYear = new Date().getUTCFullYear();
      const startIndex = state.data.findIndex(({ year, month }) => year === currentYear && month === 1);
      state.data = recalculateInventoryFromIndex([...state.data], startIndex, state.isManufacturer);
    },
    setMonthlyInventory: (state, action) => {
      let foundInventory = state.data.find(
        ({ year, month }) => year === action.payload.year && month === action.payload.month,
      );

      if (!foundInventory) {
        foundInventory = state.data.find(({ year }) => year === action.payload.year);
      }

      state.monthlyInventory = foundInventory;
    },
    setIsManufacturer: (state, action: PayloadAction<boolean>) => {
      state.isManufacturer = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setValidating: (state, action) => {
      state.validating = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInventoryAsync.fulfilled, (state, action) => {
        state.data = [...action.payload.data];
        const availableYearsSet = new Set<number>();
        state.data.forEach((inventoryPerMonth) => {
          availableYearsSet.add(inventoryPerMonth.year);
        });
        state.availableYears = Array.from(availableYearsSet);
      })
      .addCase(updateInventoryAsync.fulfilled, (state, action) => {});
    // .addMatcher(
    //   (action) => /^inventory.*rejected?/.test(action.type),
    //   (_, action) => {
    //     NotificationManager.error(action.error.message);
    //   },
    // );
    // .addMatcher(
    //   (action) => /^inventory\/(update|set|delete).*fulfilled?/.test(action.type),
    //   (_, action) => {
    //     NotificationManager.success(action.payload.message);
    //   },
    // );
  },
});

export const {
  setInventory,
  setFilters,
  updateInventoryRecord,
  updateEndingBalanceAdjustment,
  updateInventoryFromStartOfYear,
  setMonthlyInventory,
  setValidating,
  setIsManufacturer,
} = inventorySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectInventory = (state: any): InventoryPerMonthRecord[] =>
  state.inventory.data.filter((record: any) => record.year === state.inventory.filters.year);
export const selectAvailableYears = (state: any) => state.inventory.availableYears;
export const selectAvailableMonths = (year: number) => (state: { inventory: InventoryState }) =>
  state.inventory.data
    .filter((record: InventoryPerMonthRecord) => record.year === year)
    .map(({ month }) => month)
    .reverse();
export const selectFilters = (state: any) => state.inventory.filters;
export const selectValidating = (state: any) => state.inventory.validating;
export const selectMonthlyInventory = (state: any) => state.inventory.monthlyInventory;
export const selectCanUpdateInventorySales = createSelector(
  [selectMonthlyInventory],
  ({ approvedByUser }) => approvedByUser,
);

export default inventorySlice.reducer;
