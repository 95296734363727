import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// project imports
import { store } from './store';
import * as serviceWorker from './serviceWorker';
import App from './App';

// style + assets
import 'assets/scss/style.scss';

// ===========================|| REACT DOM RENDER  ||=========================== //

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
