import { useRoutes } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import { useRedirectToNewInventoryApp } from '../hooks/useRedirectToNewInventoryApp';
import { redirectRoute } from './RedirectRoute';
import { useMemo } from 'react';

// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes() {
  const { shouldOldInventoryTrackerRedirectToNewOne } = useRedirectToNewInventoryApp();

  const routes = useMemo(
    // () => [...(shouldOldInventoryTrackerRedirectToNewOne ? [redirectRoute] : []), LoginRoutes, MainRoutes],
    () => [LoginRoutes, MainRoutes],
    [shouldOldInventoryTrackerRedirectToNewOne],
  );

  return useRoutes(routes);
}
