import { makeStyles } from '@mui/styles';
import { IconInfoCircle } from '@tabler/icons';
import { selectTooltips } from '../../views/dashboard/dashboardSlice';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HtmlTooltip from '../../components/HtmlTooltip';
import { isEqual } from 'lodash';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'inline-block',
  },
}));

interface InfoTooltipProps {
  content?: React.ReactElement;
  name: string;
  className?: string;
}

export default function InfoTooltip({ content, name, className }: InfoTooltipProps) {
  const classes = useStyles();
  const [title, setTitle] = useState('loading...');
  const tooltips = useSelector(selectTooltips, isEqual);

  useEffect(() => {
    const tooltip = tooltips.length && tooltips.find((t: any) => t.fields.identifier === name);
    if (tooltip) {
      // @ts-ignore
      setTitle(<div dangerouslySetInnerHTML={{ __html: tooltip.fields.text || 'loading...' }} />);
    }
  }, [tooltips, name]);

  return (
    // @ts-ignore
    <HtmlTooltip placement="right" title={content || title} mw="400px">
      <div className={[classes.content, className].join(' ')}>
        <IconInfoCircle />
      </div>
    </HtmlTooltip>
  );
}
