import axios, { AxiosInstance } from 'axios';

class Api {
  tokenGenerator: any;

  #client: AxiosInstance | undefined;

  get client(): AxiosInstance {
    if (!this.#client) {
      throw new Error('Api client does not exist');
    }

    return this.#client;
  }

  initClient() {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    client.interceptors.request.use(
      async (config) => {
        const token = await this.getToken();

        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
        };
      },
      (error) => {
        Promise.reject(error);
      },
    );

    this.#client = client;
  }

  get(path: any, payload?: any, params?: any) {
    return this.client.get(path, { params: payload, ...(params || {}) });
  }

  post(path: any, payload: any, params?: any) {
    return this.client.post(path, payload, params);
  }

  put(path: any, payload: any, params?: any) {
    return this.client.put(path, payload, params);
  }

  delete(path: any, payload?: any) {
    return this.client.delete(path, payload);
  }

  setTokenGenerator(tokenGenerator: any) {
    this.tokenGenerator = tokenGenerator;
  }

  getToken() {
    return this.tokenGenerator();
  }
}

export default new Api();
