import { handleResponse } from '../../utils/helpers';
import Api from '../../services/api';
import { ProductSalesChannel } from '../../types';

export async function fetchChannels() {
  return Api.get('channel').then((response: any) => response);
}

export function updateChannel(inventoryChannel: ProductSalesChannel) {
  return Api.put(`channel/${inventoryChannel.channel}`, { ...inventoryChannel }).then((response: any) => response);
}

export function fetchProducts(filters: any) {
  return Api.get('product', filters).then((response: any) => response);
}

export function fetchProduct({ productId }: any) {
  return Api.get(`product/${productId}`).then((response: any) => response);
}

export function storeProduct(product: any) {
  return Api.post('product', product).then((response: any) => response);
}

export function updateProduct(product: any) {
  return Api.put(`product/${product.id}`, product).then((response: any) => response);
}

export function deleteProduct(id: any) {
  return Api.delete(`product/${id}`).then((response: any) => response);
}

export function updateProducts(updatedProducts: any) {
  return Api.put(`product`, updatedProducts).then((response: any) => response);
}

export function fetchAffectedMonths(updatedProducts: any) {
  // What does this actually do?
  return fetch(`${process.env.REACT_APP_API_URL}sales-report/affected/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `token ${localStorage.getItem('auth_token')}`,
    },
    method: 'POST',
    body: JSON.stringify(updatedProducts),
  }).then(handleResponse);
}
