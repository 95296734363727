import { TablePagination } from '@mui/material';
import { selectFilters, selectTotal, setFilters } from '../salesSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {isEqual} from "lodash";

export const SalesListPagination = () => {
  const storeProductFilters = useSelector(selectFilters,isEqual);
  const dispatch = useDispatch();

  const storeProductsTotal = useSelector(selectTotal);

  return (

    <TablePagination
      component="div"
      count={storeProductsTotal}
      rowsPerPage={storeProductFilters.pageSize}
      rowsPerPageOptions={[]}
      page={storeProductFilters.page}
      onPageChange={(_, page) => dispatch(setFilters({ ...storeProductFilters, page }))}
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      onRowsPerPageChange={(_: any, data: any) =>
        dispatch(setFilters({ ...storeProductFilters, pageSize: data.props.value, page: 0 }))
      }
    />
  );
};
