import React, { useState } from 'react';
import SalesPanel from './SalesPanel/SalesPanel';

// material-ui
import { makeStyles } from '@mui/styles';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// project imports
import MainCard from '../../components/cards/MainCard';

// style constant
const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '1400px',
    margin: 'auto',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('sm')]: {
      width: 280,
    },
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
    padding: theme.spacing(5),
    overflowY: 'auto',
    borderRadius: '2px',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1024px',
    },
  },
  appBar: {
    position: 'relative',
  },
}));

export default function SalesDialog() {
  const classes = useStyles();
  const [modalIsClosing, setModalIsClosing] = useState(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="relative" sx={{ alignItems: 'flex-end' }}>
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" aria-label="close" onClick={() => setModalIsClosing(true)}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <MainCard className={classes.paper} content={false}>
        <div>
          <SalesPanel modalIsClosing={modalIsClosing} setModalIsClosing={setModalIsClosing} />
        </div>
      </MainCard>
    </Box>
  );
}
