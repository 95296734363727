import { styled } from '@mui/material';
import { IconHelp as OriginalIconHelp, IconSettings as OriginalIconSettings } from '@tabler/icons';

export const IconHelp = styled(OriginalIconHelp)(
  // @ts-ignore
  ({ theme }) => `color: ${theme.palette.secondary[800]};`,
);

export const IconSettings = styled(OriginalIconSettings)(
  // @ts-ignore
  ({ theme }) => ` color: ${theme.palette.secondary[800]};`,
);
