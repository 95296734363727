import { Autocomplete, Paper, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    borderRadius: '2px',
    '& fieldset': {
      borderRadius: '2px',
    },
    '& label': {
      fontWeight: 500,
      color: 'black',
    },
  },
}));

const options = ({ children }: any) => <Paper sx={{ '& .MuiAutocomplete-option': { p: 1.5 } }}>{children}</Paper>;
type SelectProps = Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> & { label: string };

export default function Select(params: SelectProps) {
  const classes = useStyles();
  const { label } = params;

  return (
    <Autocomplete
      disableClearable
      {...params}
      PaperComponent={options}
      renderInput={(par) => <TextField className={classes.input} {...par} label={label || 'Search'} />}
    />
  );
}
