import { Link } from '@mui/material';
import React from 'react';
import NoteCard from '../../../components/cards/NoteCard';

export const HelpNote = () => (
  <NoteCard>
    Accurate inventory and Cost of Goods Sold (&quot;COGS&quot;) tracking is key for your business. Each month, input
    your costs for inventory purchased and/or manufactured and your COGS below. You can also choose to add info on
    giveaways, dead inventory, or any other adjustment for inaccuracies. Need some more guidance? No problem, just check out
    our{' '}
    <Link
      target="_blank"
      rel="noopener"
      href="https://finaloop.zendesk.com/hc/en-us/articles/4415747619601-Monthly-Inventory-Tracker-Overview"
    >
      help pages
    </Link>
    .
  </NoteCard>
);
