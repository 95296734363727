import Select from '../../../components/Form/Select';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesAsync, selectFilters, setFilters } from '../salesSlice';
import {
  selectAvailableMonths,
  selectAvailableYears,
  selectMonthlyInventory,
  setMonthlyInventory,
} from '../../inventory/inventorySlice';
import React, { useEffect, useRef, useState } from 'react';
import DebounceSearchInput from '../../../components/DebounceSearchInput/DebounceSearchInput';
import { monthsList } from '../../../utils/months';
import { SalesSummary } from '../SalesPanel/Components';
import { isEqual } from 'lodash';

function SalesFilters() {
  const allMonths = useRef(monthsList);
  const [months, setMonths] = useState(allMonths.current);

  const dispatch = useDispatch();
  const storeSalesFilters = useSelector(selectFilters, isEqual);
  const storeAvailableYears = useSelector(selectAvailableYears, isEqual);
  const monthlyInventory = useSelector(selectMonthlyInventory, isEqual);
  const availableMonths = useSelector(selectAvailableMonths(storeSalesFilters.year), isEqual);

  useEffect(() => {
    setMonths(availableMonths.map((month) => allMonths.current[month - 1]));
  }, [availableMonths]);

  useEffect(() => {
    if (monthlyInventory.month !== storeSalesFilters.month || monthlyInventory.year !== storeSalesFilters.year) {
      dispatch(
        setFilters({ ...storeSalesFilters, month: monthlyInventory.month, year: monthlyInventory.year, page: 0 }),
      );
      dispatch(getSalesAsync());
    }
  }, [dispatch, monthlyInventory, storeSalesFilters]);

  const filterChangeHandler = (name: any, value: any) => {
    if (name === 'month') {
      dispatch(setMonthlyInventory({ month: value, year: storeSalesFilters.year }));
    } else if (name === 'year') {
      dispatch(setMonthlyInventory({ month: storeSalesFilters.month, year: value }));
    } else {
      dispatch(setFilters({ ...storeSalesFilters, [name]: value, page: 0 }));
    }
  };

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} justifyContent="space-between" mb={1}>
      <Box display="flex" gap="20px">
        <Box width={150}>
          <Select
            options={months}
            value={storeAvailableYears.length ? months[storeSalesFilters.month - 1] : ''}
            getOptionLabel={(option: any) => option.toString()}
            isOptionEqualToValue={(option: any, value: any) => option === value}
            onChange={(_: any, value: any) => filterChangeHandler('month', months.findIndex((m) => m === value) + 1)}
            label="Month"
          />
        </Box>

        <Box width={150}>
          <Select
            options={storeAvailableYears}
            value={storeAvailableYears.length ? storeSalesFilters.year : ''}
            getOptionLabel={(option: any) => option.toString()}
            isOptionEqualToValue={(option: any, value: any) => option === value}
            onChange={(_: any, value: any) => filterChangeHandler('year', value)}
            label="Year"
          />
        </Box>
      </Box>

      <DebounceSearchInput
        initialValue={storeSalesFilters.query}
        onChange={(value: any) => filterChangeHandler('query', value)}
        placeholder="Search Product / SKU"
      />

      <SalesSummary />
    </Box>
  );
}

export default SalesFilters;
