import { Box, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import InfoTooltip from '../../../components/InfoTooltip/InfoTooltip';
import React from 'react';
import { styled } from '@mui/styles';
import { useSelector } from 'react-redux';
import { selectIsManufacturer } from '../../settings/settingsSlice';

export const InventoryListHead = () => {
  const isManufacturer = useSelector(selectIsManufacturer);

  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" sx={{ borderRight: '1px dashed lightgray' }}>
          <Typography variant="subtitle1">Month</Typography>
        </TableCell>

        <TableCell align="center" sx={{ borderRight: '1px dashed lightgray' }}>
          <WrapperBox>
            <Typography variant="subtitle1">Beginning Inventory</Typography>
            {isManufacturer ? (
              <FloatingInfoTooltip name="Manufacturers Monthly Inventory Tracker - A Beginning Inventory" />
            ) : (
              <FloatingInfoTooltip name="Regular Monthly Inventory Tracker - A Beginning Inventory" />
            )}
          </WrapperBox>
        </TableCell>

        <TableCell align="center" sx={{ borderRight: '1px dashed lightgray' }}>
          <WrapperBox>
            <Typography variant="subtitle1">Purchases & Manufacturing Costs</Typography>
            {isManufacturer ? (
              <FloatingInfoTooltip name="Manufacturers Monthly Inventory Tracker - B Purchases & Manufacturing Costs" />
            ) : (
              <FloatingInfoTooltip name="Regular Monthly Inventory Tracker - B Purchases & Manufacturing Costs" />
            )}
          </WrapperBox>
        </TableCell>

        <TableCell sx={{ borderRight: '1px dashed lightgray' }} align="center">
          <WrapperBox>
            <Typography variant="subtitle1">COGS</Typography>
            {isManufacturer ? (
              <FloatingInfoTooltip name="Manufacturers Monthly Inventory Tracker - C COGS" />
            ) : (
              <FloatingInfoTooltip name="Regular Monthly Inventory Tracker - C COGS" />
            )}
          </WrapperBox>
        </TableCell>

        <TableCell sx={{ borderRight: '1px dashed lightgray' }} align="center">
          <WrapperBox>
            <Typography variant="subtitle1">Giveaways & Dead Inventory</Typography>
            {isManufacturer ? (
              <FloatingInfoTooltip name="Manufacturers Monthly Inventory Tracker - D Giveaways & Dead Inventory" />
            ) : (
              <FloatingInfoTooltip name="Regular Monthly Inventory Tracker - D Giveaways & Dead Inventory" />
            )}
          </WrapperBox>
        </TableCell>

        <TableCell sx={{ borderRight: '1px dashed lightgray' }} align="center">
          <WrapperBox>
            <Typography variant="subtitle1">Adjustments for Inaccuracies</Typography>
            <FloatingInfoTooltip name="Monthly Inventory Tracker - COGS & Others" />
            {isManufacturer ? (
              <FloatingInfoTooltip name="Manufacturers Monthly Inventory Tracker - E Inventory Balance Adjustments" />
            ) : (
              <FloatingInfoTooltip name="Regular Monthly Inventory Tracker - E Inventory Balance Adjustments" />
            )}
          </WrapperBox>
        </TableCell>

        <TableCell align="center" sx={{ pr: 2 }}>
          <WrapperBox>
            <Typography variant="subtitle1">Ending Inventory</Typography>
            <FloatingInfoTooltip name="Monthly Inventory Tracker - Ending Inventory" />
            {isManufacturer ? (
              <FloatingInfoTooltip name="Manufacturers Monthly Inventory Tracker - F Ending Inventory" />
            ) : (
              <FloatingInfoTooltip name="Regular Monthly Inventory Tracker - F Ending Inventory" />
            )}
          </WrapperBox>
        </TableCell>

        <TableCell />
      </TableRow>
    </TableHead>
  );
};

const FloatingInfoTooltip = styled(InfoTooltip)({
  position: 'absolute',
  right: '10px',
});

const WrapperBox = ({ children }: { children: JSX.Element[] }) => (
  <Box display="flex" maxWidth="100px" margin="auto" alignItems="center" justifyContent="center">
    {children}
  </Box>
);
