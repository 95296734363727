import React from 'react';
import NotchedOutline from '@mui/material/OutlinedInput/NotchedOutline';
import { styled } from '@mui/material';

const Root = styled('div')`
  position: relative;
  margin: auto;
`;

const FakeNotchedOutline = styled(NotchedOutline)`
  border-color: ${(props) => (props.disabled ? '#eeeeee !important' : '#b0bac9 !important')};
`;

const ContentWrapper = styled('div')`
  position: relative;
`;

const Content = styled(`div`)`
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20.125px;
  height: 33px;
  color: ${(props: { disabled: boolean }) => (props.disabled ? 'rgba(0,0,0,0.38)' : 'black')};
`;

export const LabelledOutline = ({ id, label, children, onClick, disabled, ...otherProps }: any) => {
  return (
    <Root {...otherProps}>
      <ContentWrapper>
        <Content disabled={!!disabled} id={id} onClick={onClick}>
          {children}

          <FakeNotchedOutline disabled={!!disabled} notched />
        </Content>
      </ContentWrapper>
    </Root>
  );
};
