import React from 'react';

import { Grid, Table, TableBody, TableContainer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { selectDisplayedSales } from '../salesSlice';
import { SalesListRow } from './SalesListRow';
import { SalesListHead } from './SalesListHead';
import { SalesListPagination } from './SalesListPagination';
import { isEqual } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
    backgroundColor: theme.palette.primary.light,
    maxHeight: 'calc(100vh - 400px)',
    overflowX: 'auto',
  },
  table: {
    width: 'calc(100% - 1px)',
    minWidth: 350,
    color: 'black',
    '& tr': {
      border: '1px solid',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      borderColor: theme.palette.secondary.lighter,
      borderBottom: '10px solid',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      borderBottomColor: theme.palette.primary.light,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      backgroundColor: theme.palette.background.paper,
    },
    '& thead tr:last-child': {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      backgroundColor: theme.palette.primary.light,
      border: 'none',
      boxSizing: 'border-box',

      '& th': {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        color: theme.palette.grey[500],
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
        [theme.breakpoints.down('lg')]: {
          fontSize: '12px',
        },

        '&.channel': {
          '& img': {
            height: '20px',
            verticalAlign: 'middle',
            marginRight: '5px',
          },
          '& span': {
            verticalAlign: 'middle',
          },
        },
      },
      '& svg': {
        stroke: 'white',
        verticalAlign: 'middle',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        fill: theme.palette.primary[900],
      },
    },
    '& input': {
      // ...theme.typography.input,
    },
    '& td': {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      color: theme.palette.secondary[1000],
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      paddingLeft: theme.spacing(1),
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      paddingRight: theme.spacing(1),
      paddingTop: 0,
      paddingBottom: 0,
      maxWidth: '150px',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
      // ...theme.typography.input,
    },
  },
  noData: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
    padding: theme.spacing(10),
    textAlign: 'center',
    display: 'block',
  },
}));

export default function SalesList() {
  const classes = useStyles();
  const displayedSalesIds = useSelector(selectDisplayedSales, isEqual);

  return (
    <Grid>
      <TableContainer className={classes.root}>
        <Table className={classes.table} aria-label="simple table">
          <SalesListHead />

          <TableBody>
            {displayedSalesIds.length
              ? displayedSalesIds.map((rowId: any) => <SalesListRow key={rowId} rowId={rowId} />)
              : null}
          </TableBody>
        </Table>
        {!displayedSalesIds.length ? (
          <Typography className={classes.noData} variant="h5">
            No Data
          </Typography>
        ) : null}
      </TableContainer>

      <SalesListPagination />
    </Grid>
  );
}
