import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useEffect, useRef, useState } from 'react';
import { LabelledOutline } from './LabeledOutline';
import { red } from '@mui/material/colors';
import { formatToUSD } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type '{}'.
    width: (props) => (typeof props.width === 'number' ? theme.spacing(props.width) : props.width),
    margin: 'auto',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('lg')]: {
      width: '80px !important',
    },
    '& fieldset': {
      borderRadius: '2px',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      borderColor: `${theme.palette.grey[200]}!important`,
      color: 'black',
    },
    '& input': {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'textAlign' does not exist on type '{}'.
      textAlign: (props) => props.textAlign,
      borderRadius: '2px',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      fontSize: theme.spacing(1.5),
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      padding: theme.spacing(1),
      color: 'black',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
  },
  formatted: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  enabled: {
    '& fieldset': {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      borderColor: `${theme.palette.grey[500]}!important`,
    },
    '& input': {
      backgroundColor: '#fff',
    },
  },
  error: {
    '& fieldset': {
      borderColor: `${red[500]}!important`,
    },
  },
}));

const InputCell = memo(
  ({
    value,
    format,
    onChange,
    focused,
    width = '130px',
    textAlign = 'center',
    sx,
    fake,
    error,
    onBlur,
    ...otherProps
  }: any) => {
    const classes = useStyles({ width, textAlign });
    const [isFocused, setIsFocused] = useState(focused);
    const [textValue, setTextValue] = useState(value);
    const [formattedTextValue, setFormattedTextValue] = useState(value);
    const textRef = useRef(null);

    useEffect(() => {
      setTextValue(value === null ? '' : value);
    }, [value]);

    useEffect(() => {
      format && setFormattedTextValue(formatToUSD(value));
    }, [value, format]);

    useEffect(() => {
      if (isFocused && !otherProps.disabled) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        textRef.current.select();
      }
    }, [otherProps.disabled, isFocused]);

    const keyDownHandler = (e: any) => {
      if (e.key === 'Enter') {
        setIsFocused(false);
      }
    };

    const onTextChanged = (e: any) => {
      setTextValue(e.target.value);
      onChange(e);
    };

    return !isFocused ? (
      fake ? (
        <LabelledOutline
          onClick={(e: any) => {
            e.preventDefault();
            setIsFocused(true);
          }}
          {...otherProps}
        >
          {formattedTextValue}
        </LabelledOutline>
      ) : (
        <TextField
          className={[
            classes.root,
            !otherProps.disabled ? classes.enabled : '',
            error ? classes.error : '',
            format ? classes.formatted : '',
          ].join(' ')}
          id="outlined-basic"
          value={formattedTextValue}
          onFocus={(e) => {
            e.preventDefault();
            setIsFocused(true);
          }}
          sx={{ display: isFocused ? 'none' : 'inline-flex', ...sx }}
          color="myColors"
          {...otherProps}
          type="text"
          error={error}
        />
      )
    ) : fake && otherProps.disabled ? (
      <LabelledOutline
        onClick={(e: any) => {
          e.preventDefault();
          setIsFocused(true);
        }}
        {...otherProps}
      >
        {formattedTextValue}
      </LabelledOutline>
    ) : (
      <TextField
        className={[
          classes.root,
          !otherProps.disabled ? classes.enabled : '',
          error ? classes.error : '',
          format ? classes.formatted : '',
        ].join(' ')}
        value={textValue}
        onChange={onTextChanged}
        onKeyDown={keyDownHandler}
        onBlur={() => {
          onBlur && onBlur();
          setIsFocused(false);
        }}
        inputRef={textRef}
        focused={isFocused}
        sx={{ display: isFocused ? 'inline-flex' : 'none', ...sx }}
        color="myColors"
        error={error}
        {...otherProps}
      />
    );
  },
);

export default InputCell;
