import { Box, CardContent, Link, styled, Typography } from '@mui/material';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { selectCompanyName } from '../../../settings/settingsSlice';
import { IconHelp } from '../../../../components/ColoredIcon';

const TopTitleCardContent = styled(CardContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0 !important;
`;

const CompanyName = styled('span')(({ theme }) => ({
  color: theme.palette.info.main,
}));

export const TopTitle = () => {
  const companyName = useSelector(selectCompanyName);

  return (
    <Box display="flex" flexDirection="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="h4" align="left">
        COGS Calculator <CompanyName> {companyName && ` -  ${companyName}`}</CompanyName>
      </Typography>

      <Box display="flex" flexDirection="row" justifyContent="end">
        <Link
          target="_blank"
          rel="noopener"
          href="https://finaloop.zendesk.com/hc/en-us/articles/4524103072017-COGS-Calculator"
        >
          <IconHelp />
        </Link>
      </Box>
    </Box>
  );
};
