import { Box } from '@mui/material';
import React from 'react';
import FormButton from '../../components/Form/FormButton';
import Modal from './Modal';

export default function ConfirmationModal({
  children,
  text,
  open,
  onConfirm,
  onCancel,
  confirmText = 'Confirm',
  cancelText = 'cancel',
  onClose,
  ...otherProps
}: any) {
  return (
    <Modal
      onClose={onClose}
      noHeight
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      {...otherProps}
    >
      <Box overflow="hidden">{children}</Box>

      <Box display="flex" justifyContent="flex-end" gap={2}>
        <FormButton
          secondary
          color="primary"
          variant="contained"
          size="normal"
          // startIcon={<IconRotateClockwise />}
          onClick={onCancel}
        >
          {cancelText}
        </FormButton>
        <FormButton
          color="primary"
          variant="contained"
          size="normal"
          // startIcon={<IconRotateClockwise />}
          onClick={onConfirm}
        >
          {confirmText}
        </FormButton>
      </Box>
    </Modal>
  );
}
