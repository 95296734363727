import Api from '../../services/api';
import { Company } from '../../types';

export function fetchCompany() {
  return Api.get('company').then((response: any) => response);
}

export function updateCompany(company: Partial<Company>) {
  return Api.put(`company`, company).then((response: any) => response);
}
