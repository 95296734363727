import { Box, styled } from '@mui/material';
import { IconCalculator } from '@tabler/icons';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenedModal } from 'views/dashboard/dashboardSlice';
import { setMonthlyInventory } from '../inventorySlice';
import { setFilters } from '../../sales/salesSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { ComingSoonDialog } from 'components/ComingSoonDialog/ComingSoonDialog';
import { useTheme } from '@mui/material/styles';
import { ExtendedPaletteColor } from '../../../themes/palette';
import { grey } from '@mui/material/colors';

const Calculator = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(0.5),
  color: theme.palette.primary.main,
  alignItems: 'center',
  marginTop: '5px',
  cursor: 'pointer',
  position: 'absolute',
  bottom: '1em',
  right: '14%',
}));

export const CogsCalculator = ({ row: { month, year, editable, approvedByUser } }: any) => {
  const [openComingSoon, setOpenComingSoon] = useState(false);
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const theme = useTheme();
  const [cogsColor, setCogsColor] = useState((theme.palette.secondary as ExtendedPaletteColor)[800]);
  const [canUseCogsCalculator, setCanUseCogsCalculator] = useState(false);
  const [canSeeCogsCalculator, setCanSeeCogsCalculator] = useState(true);

  useEffect(() => {
    if (year === 2021 && month < 12) {
      setCanSeeCogsCalculator(false);
    }
  }, [year, month]);

  useEffect(() => {
    if (approvedByUser) {
      setCogsColor(grey[500]);
    } else {
      setCogsColor((theme.palette.secondary as ExtendedPaletteColor)[800]);
    }
  }, [theme, approvedByUser]);

  useEffect(() => {
    const useCalculator = user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/useCalculator`];
    if (useCalculator) {
      setCanUseCogsCalculator(true);
    }
  }, [user]);

  const onCalculatorClick = () => {
    dispatch(setMonthlyInventory({ month, year }));
    dispatch(
      setFilters({
        year,
        month,
        query: '',
        pageSize: 100,
        page: 0,
      }),
    );
    dispatch(setOpenedModal('products'));
  };

  const onComingSoonClick = () => {
    setOpenComingSoon(true);
  };

  const handleComingSoonClose = (value: any) => {
    setOpenComingSoon(false);
  };

  return !editable || !canSeeCogsCalculator ? null : canUseCogsCalculator ? (
    <Calculator onClick={onCalculatorClick}>
      <Typography variant="subtitle2" sx={{ color: cogsColor }}>
        COGS Calculator
      </Typography>
      <IconCalculator height="20px" color={cogsColor} />
    </Calculator>
  ) : (
    <>
      <ComingSoonDialog open={openComingSoon} onClose={handleComingSoonClose} />
      <Calculator onClick={onComingSoonClick}>
        <Typography variant="subtitle2" sx={{ color: cogsColor }}>
          COGS Calculator
        </Typography>
        <IconCalculator height="20px" color={cogsColor} />
      </Calculator>
    </>
  );
};
