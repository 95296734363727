import { Chip, styled } from '@mui/material';
import React from 'react';

const SourceImage = styled('img')`
  height: 20px;
  width: 20px;
`;

export const ProductSourceChannel = ({ sourceChannel, channels }: any) => {
  const inventoryChannel = channels.find((channel: any) => channel.productSource === sourceChannel);

  return inventoryChannel?.channel === 'Shopify' ? (
    <SourceImage src={inventoryChannel.image} alt={inventoryChannel.channel} />
  ) : (
    <Chip label={sourceChannel} sx={{ bgcolor: '#757575', color: 'white' }} />
  );
};
