import { addQueryToURL, handleResponse } from '../../utils/helpers';
import Api from '../../services/api';
import { AxiosResponse } from 'axios';
import { ProductSalesPerMonth } from '../../types';

export function fetchSales(filters: any): Promise<AxiosResponse<ProductSalesPerMonth[]>> {
  return Api.get('product-sales-per-month', filters).then((response: any) => response);
}

export function fetchSaleWithPolling({ yearAndMonthAndProductId }: any) {
  return Api.get(`product-sales-per-month/${yearAndMonthAndProductId}`).then((response: any) => response);
}

export function updateSales(updatedSales: any) {
  return Api.put('product-sales-per-month', updatedSales).then((response: any) => response);
}

export function fetchLastUpdated() {
  return Api.get('product-sales-per-month/last-update').then((response: any) => response);
}

export function fetchOrders(filters: any) {
  return fetch(addQueryToURL(`${process.env.REACT_APP_API_URL}order/`, filters), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `token ${localStorage.getItem('auth_token')}`,
    },
  }).then(handleResponse);
}
