import { Box, Icon, Stack, Tooltip, Typography } from '@mui/material';
import { formatToUSD } from '../../../utils/helpers';
import React, { useEffect, useState } from 'react';
import InputCell from '../../../components/Form/InputCell';
import { makeStyles } from '@mui/styles';
import { IconEdit } from '@tabler/icons';
import { green, grey, red } from '@mui/material/colors';
import { monthsList } from '../../../utils/months';
import { CogsCalculator } from './CogsCalculator';
import { selectValidating } from '../inventorySlice';
import { useSelector } from 'react-redux';
import { InventoryTableCell, InventoryTableRow } from './TableParts';
import { selectIsManufacturer } from '../../settings/settingsSlice';
import { MultipleInputsCell } from './TableParts/MultipleInputsCells';
import { ButtonCell } from './TableParts/ButtonCell';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  inventoryCostInput: {
    '& input': {
      backgroundColor: grey[100],
    },
  },
  inventoryCostInputInvalid: {
    '& input': {
      backgroundColor: red[100],
    },
  },
  actions: {
    '& svg': {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      stroke: theme.palette.secondary[800],
      strokeWidth: '2px',
      cursor: 'pointer',
    },
    '&.disabled': {
      '& svg': {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        stroke: theme.palette.grey[500],
      },
    },
  },
}));

export default function InventoryListRow({
  row,
  editCellPropsChangeHandler,
  editableRows,
  editHandler,
  removeEditRow,
  openEndingBalanceDialog,
  isLast,
  index,
}: any) {
  const classes = useStyles();
  const [isEditable, setIsEditable] = useState(false);
  const isValidating = useSelector(selectValidating);
  const isManufacturer = useSelector(selectIsManufacturer);

  const onEndingBalanceAdjustmentClick = () => {
    openEndingBalanceDialog(row);
  };

  useEffect(() => {
    setIsEditable(editableRows.includes(row.yearAndMonth));
  }, [editableRows]);

  return (
    <InventoryTableRow>
      <InventoryTableCell sx={{ borderRight: '1px dashed lightgray', paddingTop: '46px !important' }} scope="row">
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Typography align="center"> {monthsList[row.month - 1]}</Typography>
          {row.synced && (
            <Stack direction="row" alignItems="center" gap={0.5} justifyContent="center" marginTop={1}>
              <CheckCircleOutlineIcon sx={{ color: '#06A801', fontSize: 12 }} />
              <Typography align="center" fontSize={11}>
                Synced
              </Typography>
            </Stack>
          )}
        </Box>
      </InventoryTableCell>

      {isManufacturer ? (
        <MultipleInputsCell align="center" className={isLast ? undefined : classes.inventoryCostInput}>
          <Box display="flex" flexDirection="column" sx={{ display: 'flex', pt: 2, pb: 0, gap: 2 }}>
            <InputCell
              value={row.initialSuppliesAndMaterialsCost}
              format
              onChange={(e: any) => editCellPropsChangeHandler(e, row, 'initialSuppliesAndMaterialsCost', true)}
              type="number"
              disabled={!isEditable || !isLast}
              label="Supplies & Materials"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <InputCell
              value={row.initialInventoryCost}
              format
              type="number"
              onChange={(e: any) => editCellPropsChangeHandler(e, row, 'initialInventoryCost')}
              disabled={!isEditable || !isLast}
              label="Ready for Sale"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </MultipleInputsCell>
      ) : (
        <InventoryTableCell align="center" className={isLast ? undefined : classes.inventoryCostInput}>
          <InputCell
            value={row.initialInventoryCost}
            format
            type="number"
            sx={{ pt: 2 }}
            onChange={(e: any) => editCellPropsChangeHandler(e, row, 'initialInventoryCost')}
            disabled={!isEditable || !isLast}
          />
        </InventoryTableCell>
      )}

      <InventoryTableCell
        align="center"
        sx={{ borderRight: '1px dashed lightgray', borderLeft: '1px dashed lightgray' }}
      >
        <InputCell
          value={row.purchasedInventoryCost}
          format
          onChange={(e: any) => editCellPropsChangeHandler(e, row, 'purchasedInventoryCost')}
          type="number"
          sx={{ pt: 2 }}
          disabled={!isEditable}
          error={isValidating && !row.purchasedInventoryCost && row.purchasedInventoryCost !== 0}
        />
      </InventoryTableCell>

      <MultipleInputsCell align="center" sx={{ borderRight: '1px dashed lightgray' }}>
        <InputCell
          value={row.cogs}
          format
          onChange={(e: any) => editCellPropsChangeHandler(e, row, 'cogs')}
          type="number"
          disabled={!isEditable}
          error={isValidating && !row.cogs && row.cogs !== 0}
        />
        <CogsCalculator row={row} />
      </MultipleInputsCell>

      <MultipleInputsCell align="center">
        <Box display="flex" flexDirection="column" sx={{ display: 'flex', pt: 2, pb: 2, gap: 2 }}>
          <InputCell
            value={row.giveawayInventoryCost}
            format
            onChange={(e: any) => editCellPropsChangeHandler(e, row, 'giveawayInventoryCost')}
            type="number"
            disabled={!isEditable}
            label="Giveaways"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <InputCell
            value={row.deadInventoryCost}
            format
            onChange={(e: any) => editCellPropsChangeHandler(e, row, 'deadInventoryCost')}
            type="number"
            disabled={!isEditable}
            label="Dead Inventory"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </MultipleInputsCell>

      <InventoryTableCell
        align="center"
        sx={{ borderRight: '1px dashed lightgray', borderLeft: '1px dashed lightgray' }}
      >
        {index === 0 ? (
          <ButtonCell
            value={row.endingBalanceAdjustments}
            disabled={!isEditable}
            sx={{ maxWidth: '130px', pt: 2 }}
            onClick={onEndingBalanceAdjustmentClick}
          />
        ) : (
          <InputCell
            value={row.endingBalanceAdjustments}
            format
            onChange={(e: any) => editCellPropsChangeHandler(e, row, 'endingBalanceAdjustments', true)}
            type="number"
            sx={{ pt: 2 }}
            disabled={!isEditable}
          />
        )}
      </InventoryTableCell>

      {isManufacturer ? (
        <MultipleInputsCell align="center">
          <Box display="flex" flexDirection="column" sx={{ display: 'flex', pt: 2, pb: 2, gap: 2 }}>
            <InputCell
              value={row.suppliesAndMaterialsCost}
              format
              onChange={(e: any) => editCellPropsChangeHandler(e, row, 'suppliesAndMaterialsCost', true)}
              type="number"
              disabled={!isEditable}
              label="Supplies & Materials"
              InputLabelProps={{
                shrink: true,
              }}
              error={isValidating && !row.suppliesAndMaterialsCost && row.suppliesAndMaterialsCost !== 0}
            />

            <div className={row.endInventoryCost >= 0 ? classes.inventoryCostInput : classes.inventoryCostInputInvalid}>
              <InputCell
                value={row.endInventoryCost}
                format
                type="number"
                disabled
                label="Ready for Sale"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </Box>
        </MultipleInputsCell>
      ) : (
        <InventoryTableCell
          align="center"
          className={row.endInventoryCost >= 0 ? classes.inventoryCostInput : classes.inventoryCostInputInvalid}
        >
          <InputCell sx={{ pt: 2 }} value={row.endInventoryCost} format type="number" disabled />
        </InventoryTableCell>
      )}

      <InventoryTableCell sx={{ pr: 2, minWidth: 6 }} align="center" className={classes.actions}>
        {row.editable ? (
          <Tooltip title="Enable month editing">
            <Box sx={{ pt: 2 }}>
              <IconEdit
                onClick={() =>
                  editableRows.includes(row.yearAndMonth)
                    ? removeEditRow(row.yearAndMonth)
                    : editHandler(row.yearAndMonth)
                }
              />
            </Box>
          </Tooltip>
        ) : null}
      </InventoryTableCell>
    </InventoryTableRow>
  );
}
