import { createAsyncThunk, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from '../dashboard/dashboardSlice';
import { fetchLastUpdated, fetchSales, fetchSaleWithPolling, updateSales } from './salesAPI';
import { format } from 'date-fns';
import BigNumber from 'bignumber.js';
import { createSelector } from 'reselect';
import { ChannelSales, PRODUCT_VARIANT_UNKNOWN_ID, ProductSalesPerMonth, SalesFilters, SalesSate } from '../../types';

const initialState: SalesSate = {
  data: [],
  updatedData: [],
  autoUpdate: false,
  total: 0,
  lastUpdated: '',
  aggregated: {
    cogs: 0,
    giveawaysSum: 0,
    deadInventorySum: 0,
  },
  filters: {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    query: '',
    pageSize: 100,
    page: 0,
  },
};

const callAPIWithLoader = async (callback: any, dispatch: any, ...args: any[]) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  dispatch(startLoading());
  try {
    const response = await callback(...args);
    return response;
  } finally {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    dispatch(stopLoading());
  }
};

export const getSalesAsync = createAsyncThunk(
  'sales/getSales',
  (_, { dispatch, getState }: { dispatch: Dispatch; getState: any }) =>
    callAPIWithLoader(fetchSales, dispatch, { ...getState().sales.filters, query: '' }),
);

export const getSaleAsyncWithPolling = createAsyncThunk('sales/getSale', (sale, { dispatch }) =>
  callAPIWithLoader(fetchSaleWithPolling, dispatch, sale),
);

export const getLastUpdatedAsync = createAsyncThunk('sales/getLastUpdated', (_, { dispatch }) =>
  callAPIWithLoader(fetchLastUpdated, dispatch),
);

export const updateSalesAsync = createAsyncThunk('sales/updateSales', (updatedSales, { dispatch, getState }) => {
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  const updatedSalesData = getState().sales.updatedData;
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  const { month, year } = getState().sales.filters;
  return callAPIWithLoader(updateSales, dispatch, { month, year, updatedSales: updatedSalesData });
});

const addProductSalesToAggregate = (
  aggregated: any,
  { purchasePrice, manualOverriddenPurchasePrice, giveaways, deadInventory, channelsSales }: any,
  isNegative: boolean = false,
) => {
  let price = manualOverriddenPurchasePrice ?? purchasePrice;
  price = isNegative ? -price : price;
  if (!price) return;

  aggregated.deadInventorySum += deadInventory ? new BigNumber(deadInventory).times(price).toNumber() : 0;
  aggregated.giveawaysSum += giveaways ? new BigNumber(giveaways).times(price).toNumber() : 0;
  aggregated.cogs += channelsSales.reduce(
    (acc: any, channelSales: any) => new BigNumber(channelSales.totalAmount).times(price).plus(acc).toNumber(),
    0,
  );
};

const updateProductSalesToAggregate = (aggregated: any, changedSales: any) => {
  const nextAggregated = { ...aggregated };
  changedSales.forEach(({ previousSale, sale }: any) => {
    addProductSalesToAggregate(
      nextAggregated,
      {
        ...previousSale,
      },
      true,
    );

    addProductSalesToAggregate(nextAggregated, sale);
  });
  return nextAggregated;
};

const sumChannelAmounts = (channelsSales: any) =>
  channelsSales.reduce((acc: any, channelSales: any) => acc + (channelSales.amount || 0), 0);

const updateProductSaleInList = (productSale: any, sales: any) => {
  const existingProductSaleIndex = sales.findIndex(
    ({ yearAndMonthAndProductId }: any) => yearAndMonthAndProductId === productSale.yearAndMonthAndProductId,
  );

  if (existingProductSaleIndex === -1) {
    if (!productSale) return sales;
    return [productSale, ...sales];
  }
  return [productSale, ...sales.slice(0, existingProductSaleIndex), ...sales.slice(existingProductSaleIndex + 1)];
};

export const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    setSales: (state, action) => {
      state.data = action.payload;
      state.filters.page = 0;
      state.filters.query = '';
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload;
    },
    setAggregated: (state, action) => {
      state.aggregated = action.payload;
    },
    setFilters: (state, { payload }: PayloadAction<SalesFilters>) => {
      state.filters = payload;
    },
    setSalesRow: (state: SalesSate, action) => {
      const sale = action.payload;
      const nextUpdatedData = [...state.updatedData];
      const saleIndex = nextUpdatedData.findIndex(
        (prod) => prod.yearAndMonthAndProductId === sale.yearAndMonthAndProductId,
      );
      let previousSale;

      if (saleIndex > -1) {
        previousSale = nextUpdatedData[saleIndex];
        nextUpdatedData[saleIndex] = sale;
      } else {
        previousSale = state.data.find(
          ({ yearAndMonthAndProductId }) => yearAndMonthAndProductId === sale.yearAndMonthAndProductId,
        );
        nextUpdatedData.push(sale);
      }

      state.aggregated = updateProductSalesToAggregate(state.aggregated, [{ sale, previousSale }]);
      state.updatedData = nextUpdatedData;
    },
    updateAggregate: (state: SalesSate, action: PayloadAction<{ data: ProductSalesPerMonth[] }>) => {
      const aggregated = {
        cogs: 0,
        giveawaysSum: 0,
        deadInventorySum: 0,
      };
      state.data.forEach((productSales) => {
        addProductSalesToAggregate(aggregated, productSales);
      });
      state.aggregated = aggregated;
    },
    resetManualOverriddenPurchasePrice: (state) => {
      const changedSales: any = [];
      const newUpdatedData = state.updatedData.map((updatedSaleData) => {
        if (!updatedSaleData.manualOverriddenPurchasePrice) return updatedSaleData;
        const updatedSaleWithoutManualOverriddenPurchasePrice = {
          ...updatedSaleData,
          manualOverriddenPurchasePrice: undefined,
        };

        changedSales.push({ previousSale: updatedSaleData, sale: updatedSaleWithoutManualOverriddenPurchasePrice });

        return updatedSaleWithoutManualOverriddenPurchasePrice;
      });
      state.data.forEach((monthlySales) => {
        if (monthlySales.manualOverriddenPurchasePrice) {
          const index = newUpdatedData.findIndex(
            ({ yearAndMonthAndProductId }) => yearAndMonthAndProductId === monthlySales.yearAndMonthAndProductId,
          );
          if (index === -1) {
            const updatedSale = { ...monthlySales, manualOverriddenPurchasePrice: undefined };
            newUpdatedData.push(updatedSale);
            changedSales.push({ previousSale: monthlySales, sale: updatedSale });
          }
        }
      });

      state.aggregated = updateProductSalesToAggregate(state.aggregated, changedSales);
      state.updatedData = newUpdatedData;
    },
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(builder: any, t: any) => void' is not assig... Remove this comment to see the full error message
  extraReducers: (builder: any, t: any) => {
    builder
      .addCase(getSalesAsync.fulfilled, (state: SalesSate, action: PayloadAction<{ data: ProductSalesPerMonth[] }>) => {
        state.data = action.payload.data
          .filter(
            ({ yearAndMonthAndProductId, channelsSales }) =>
              !yearAndMonthAndProductId.includes(PRODUCT_VARIANT_UNKNOWN_ID) ||
              (yearAndMonthAndProductId.includes(PRODUCT_VARIANT_UNKNOWN_ID) &&
                channelsSales.find(({ amount }) => amount)),
          )
          .map((productMonthlySales: ProductSalesPerMonth) => ({
            ...productMonthlySales,
            channelsSales: transformReceivedProductChannels(productMonthlySales),
          }))
          .sort(
            (first: any, second: any) =>
              sumChannelAmounts(second.channelsSales) - sumChannelAmounts(first.channelsSales),
          );
        state.updatedData = [];
        // @ts-ignore - @todo fix later
        salesSlice.caseReducers.updateAggregate(state, action);
      })
      .addCase(getLastUpdatedAsync.fulfilled, (state: any, action: any) => {
        const lastUpdated = action.payload.data.updateDate;
        state.lastUpdated = lastUpdated && format(new Date(lastUpdated), 'MM/dd/yyyy HH:mm');
      })
      .addCase(getSaleAsyncWithPolling.fulfilled, (state: any, action: any) => {
        const productMonthlySales = action.payload.data;
        state.data = updateProductSaleInList(productMonthlySales, state.data);
      })
      .addCase(
        updateSalesAsync.fulfilled,
        (state: SalesSate, action: PayloadAction<{ data: ProductSalesPerMonth[] }>) => {
          let newData = [...state.data];
          action.payload.data.forEach((productSalesPerMonth) => {
            const index = newData.findIndex(
              ({ yearAndMonthAndProductId }) =>
                yearAndMonthAndProductId === productSalesPerMonth.yearAndMonthAndProductId,
            );
            newData = [
              ...newData.slice(0, index),
              {
                ...productSalesPerMonth,
                channelsSales: transformReceivedProductChannels(productSalesPerMonth),
              },
              ...newData.slice(index + 1),
            ];
          });
          state.updatedData = [];
          state.data = newData;
        },
      );
  },
});

const transformReceivedProductChannels = (productSalesPerMonth: ProductSalesPerMonth): ChannelSales[] =>
  productSalesPerMonth.channelsSales.map((channelSales: ChannelSales) => ({
    ...channelSales,
    totalAmount: (channelSales.amount ?? 0) + (channelSales.manualOverriddenAmount ?? 0),
  }));

export const { setSales, setLastUpdated, setAggregated, setFilters, setSalesRow, resetManualOverriddenPurchasePrice } =
  salesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSales = (state: any) => state.sales.data;
export const selectAggregated = (state: any) => state.sales.aggregated;
export const selectFilters = (state: any) => state.sales.filters;
export const selectUpdatedSales = (state: any) => state.sales.updatedData;
export const selectFilteredSales = createSelector([selectSales, selectFilters], (sales, filters) => {
  if (!filters.query) return sales;

  const query = filters.query.toLowerCase();

  return sales.filter(({ product: { name, sku } }: any) => `${name}-${sku}`.toLowerCase().includes(query));
});
export const selectTotal = createSelector([selectFilteredSales], (filteredSales) => filteredSales.length);
export const selectDisplayedSales = createSelector(
  [selectFilters, selectFilteredSales, selectUpdatedSales],
  (filters, sales, updatedSales) =>
    sales
      .slice(filters.page * filters.pageSize, filters.page * filters.pageSize + filters.pageSize)
      .map(
        (sale: any) =>
          (
            updatedSales.find(
              (updatedSale: any) => updatedSale.yearAndMonthAndProductId === sale.yearAndMonthAndProductId,
            ) || sale
          ).yearAndMonthAndProductId,
      ),
);
export const findSaleById =
  (id: string) =>
  (state: { sales: SalesSate }): ProductSalesPerMonth => {
    const foundSale =
      state.sales.updatedData.find((updatedSale: any) => updatedSale.yearAndMonthAndProductId === id) ||
      state.sales.data.find(({ yearAndMonthAndProductId }: any) => yearAndMonthAndProductId === id);
    if (!foundSale) {
      throw new Error('Invalid row');
    }

    return foundSale;
  };

export default salesSlice.reducer;
