import { styled, Typography } from '@mui/material';
import { formatToUSD } from '../../../utils/helpers';

const AmountDisplay = styled(Typography)`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: center;
`;

interface SalesOriginalPurchasePriceProps {
  price: number | undefined;
  manualOverriddenPurchasePrice: number | undefined;
  disabled: boolean;
}

export const SalesOriginalPurchasePrice = ({
  price,
  manualOverriddenPurchasePrice,
  disabled,
}: SalesOriginalPurchasePriceProps) =>
  !!manualOverriddenPurchasePrice && !!price && manualOverriddenPurchasePrice !== price ? (
    <AmountDisplay color={disabled ? '' : 'black'} variant="caption">{`${formatToUSD(
      price,
    )} in Shopify`}</AmountDisplay>
  ) : null;
