import MainCard from '../../../components/cards/MainCard';
import { Box, CardContent, Link, styled, Typography } from '@mui/material';
import { IconHelp } from '../../../components/ColoredIcon';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Api from '../../../services/api';
import { ActionButton } from '../../../components/Form/ActionButton';
import { selectCompanyName } from '../../settings/settingsSlice';
import { useSelector } from 'react-redux';

const TopTitleCardContent = styled(CardContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0 !important;
`;

const CompanyName = styled('span')(({ theme }) => ({
  color: theme.palette.info.main,
}));

export const TopTitle = () => {
  const { logout } = useAuth0();
  const companyName = useSelector(selectCompanyName);
  const logoutAndRedirect = async () => {
    await logout();
    Api.setTokenGenerator(undefined);
  };

  return (
    <MainCard sx={{ py: 0 }}>
      <TopTitleCardContent sx={{ px: 0, py: 1.5 }}>
        <Box>
          <Typography variant="h4">
            Monthly Inventory Tracker <CompanyName> {companyName && ` -  ${companyName}`}</CompanyName>
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Link
            sx={{ mr: 2 }}
            target="_blank"
            rel="noopener"
            href="https://finaloop.zendesk.com/hc/en-us/articles/4415747619601-Monthly-Inventory-Tracker-Overview"
          >
            <IconHelp />
          </Link>

          <ActionButton variant="contained" onClick={logoutAndRedirect}>
            Logout
          </ActionButton>
        </Box>
      </TopTitleCardContent>
    </MainCard>
  );
};
