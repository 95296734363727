import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { DebounceInput } from 'react-debounce-input';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& fieldset': {
      borderRadius: '2px',
    },
  },
}));

const Text = (props: any) => {
  const handleMouseDown = (event: any) => {
    event.preventDefault();
  };

  const handleClick = (e: any) => {
    e.target.value = '';
    // eslint-disable-next-line react/destructuring-assignment
    props.onChange(e);
  };

  const classes = useStyles();
  return (
    
    <TextField
      className={classes.root}
      InputProps={{
        startAdornment: (
          
          <InputAdornment position="start">
            
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: (
          
          <InputAdornment position="start">
            
            <IconButton onClick={handleClick} onMouseDown={handleMouseDown}>
              
              <ClearIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      size="small"
      {...props}
    />
  );
};

function DebounceSearchInput({
  onChange,
  initialValue,
  placeholder
}: any) {
  const [text, setText] = useState(initialValue);

  const onTextChange = (e: any) => {
    const newText = e.target.value;
    setText(newText);
    onChange(newText);
  };

  return (
    
    <DebounceInput
      minLength={2}
      debounceTimeout={300}
      onChange={onTextChange}
      value={text}
      placeholder={placeholder}
      element={Text}
    />
  );
}

export default DebounceSearchInput;
