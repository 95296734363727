import React from 'react';
import MuiModal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    maxWidth: '1024px',
    width: '80%',
    height: '80%',
    top: '10%',
    //   left: '50%',
    //   transform: 'translateX(-50%)',
    left: 0,
    right: 0,
    margin: 'auto',
    transform: 'translateZ(0)',
    minWidth: '1060px',
    boxSizing: 'border-box',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
    backgroundColor: theme.palette.background.paper,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'shadows' does not exist on type 'Default... Remove this comment to see the full error message
    boxShadow: theme.shadows[5],
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
    padding: theme.spacing(6, 4, 3),
    borderRadius: '2px',
  },
  small: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type '{}'.
    width: (props) => props.width || '400px',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'noHeight' does not exist on type '{}'.
    height: (props) => (props.noHeight ? 'auto' : '700px'),
    overflowY: 'auto',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'noHeight' does not exist on type '{}'.
    padding: (props) => (props.noHeight ? theme.spacing(3) : theme.spacing(6, 4, 3)),
    minWidth: 'auto',
    top: '50%',
    left: '50%',
    right: 'auto',
    transform: 'translate(-50%, -50%)',
  },
}));

interface ModalProps {
  children?: React.ReactElement;
  large?: boolean;
  noHeight?: boolean;
  width?: string;
  open: boolean;
}

function Modal({ children, large, noHeight, width, ...otherProps }: ModalProps) {
  const classes = useStyles({ noHeight, width });
  return (
    <div>
      <MuiModal
        {...otherProps}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={otherProps.open} timeout={500}>
          <div className={large ? classes.paper : [classes.paper, classes.small].join(' ')}>{children}</div>
        </Fade>
      </MuiModal>
    </div>
  );
}

export default Modal;
