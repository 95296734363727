import * as React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

// @ts-ignore
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

export function ComingSoonDialog(props: any) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };
  //
  // const handleListItemClick = (value: any) => {
  //   onClose(value);
  // };

  return (
    <Dialog onClose={handleClose} open={open} TransitionComponent={Transition}>
      <DialogTitle>Coming soon!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
        <DialogActions>
          <Button sx={{ textTransform: 'none' }} onClick={handleClose}>
            Notify me!
          </Button>
          <Button onClick={handleClose}>Thanks</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

ComingSoonDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
