import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from './views/dashboard/dashboardSlice';
import InventoryReducer from './views/inventory/inventorySlice';
import ProductsReducer from './views/products/productsSlice';
import SalesReducer from './views/sales/salesSlice';
import settingsReducer from './views/settings/settingsSlice';

export const store = configureStore({
  reducer: {
    products: ProductsReducer,
    dashboard: dashboardReducer,
    inventory: InventoryReducer,
    sales: SalesReducer,
    settings: settingsReducer,
  },
});
