import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// @ts-ignore
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import mixpanel from 'mixpanel-browser';
import { useAuth0 } from '@auth0/auth0-react';
import FullStory from 'react-fullstory';
import { identify } from 'react-fullstory';
import { selectShowLoading } from 'views/dashboard/dashboardSlice';
import themes from './themes';
import Loader from 'components/Loader/Loader';
import Api from 'services/api';
import Routes from './routes';
import { LogRocket } from './services/Logrocket';
import { hotjar } from './services/Hotjar';
import { LDProvider } from 'launchdarkly-react-client-sdk';
const MixpanelContext = React.createContext({});

// ===========================|| APP ||=========================== //

const App = () => {
  const showLoading = useSelector(selectShowLoading);
  const { error, isLoading, getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: process.env.NODE_ENV === 'development' });
    }
  }, []);

  useEffect(() => {
    Api.setTokenGenerator(getAccessTokenSilently);
    Api.initClient();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (user) {
      LogRocket.identify(user.sub!, {
        name: user.name || '',
        email: user.email || '',
      });

      hotjar.identify(user.sub!, { userProperty: user.email });

      identify(user.sub!, {
        uid: user.sub!,
        displayName: user.name || '',
        email: user.email || '',
      });
    }
  }, [user]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <ThemeProvider theme={themes()}>
        <Loader show={showLoading} />
      </ThemeProvider>
    );
  }

  return (
    <LDProvider clientSideID="621d0240ebd088148a53483b">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes()}>
          <FullStory org={process.env.REACT_APP_FULLSTORY_ORG!} />
          <MixpanelContext.Provider value={mixpanel}>
            <Loader show={showLoading} />
            <CssBaseline />
            <Routes />
            <NotificationContainer />
          </MixpanelContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LDProvider>
  );
};

export default App;
