import { createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import customShadows from './shadows';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */
export function theme() {
  const color = colors;

  const themeOption = {
    colors: color,
    heading: '',
    paper: '',
    backgroundDefault: '',
    background: '',
    darkTextPrimary: '',
    darkTextSecondary: '',
    textDark: '',
    menuSelected: '',
    menuSelectedBack: '',
    divider: '',
  };

  themeOption.paper = color.paper;
  themeOption.backgroundDefault = color.paper;
  themeOption.background = color.primaryLight;
  themeOption.darkTextPrimary = color.grey700;
  themeOption.darkTextSecondary = color.grey500;
  themeOption.textDark = color.grey900;
  themeOption.menuSelected = color.secondaryDark;
  themeOption.menuSelectedBack = color.secondaryLight;
  themeOption.divider = color.grey200;
  themeOption.heading = color.grey900;

  return createTheme({
    direction: 'ltr',
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: themeTypography(themeOption),
    customShadows: customShadows('light', themeOption),
    // @ts-ignore
    components: componentStyleOverrides(themeOption),
  });
}

export default theme;
