export interface InventoryPerMonthRecord {
  companyId: string;
  yearAndMonth: number;
  month: number;
  year: number;
  cogs?: number;
  initialInventoryCost: number;
  initialSuppliesAndMaterialsCost?: number;
  deadInventoryCost?: number;
  giveawayInventoryCost?: number;
  purchasedInventoryCost?: number;
  suppliesAndMaterialsCost?: number;
  endingBalanceAdjustments?: number;
  finishedGoodsCost?: number;
  approvedByUser?: boolean;
  editable?: boolean;
  endInventoryCost: number;
  synced?: boolean;
}

export enum IntegrationType {
  AMAZON = 'AMAZON',
  SHOPIFY = 'SHOPIFY',
  USER = 'USER',
}

export interface ProductInSale {
  productId: string;
  sku?: string;
  name: string;
  image?: string;
  integrationType: IntegrationType;
}

export enum ProductSalesChannelType {
  wholesale = 'Wholesale',
  shopify = 'Shopify',
}

export interface ProductSalesChannel {
  companyId: string;
  channel: ProductSalesChannelType;
  integrationType?: IntegrationType;
  image?: string;
  color?: string;
  updatePurchasePriceFromChannel: boolean;
}

export interface ChannelSales {
  manualOverriddenAmount?: number;
  amount?: number;
  channel: ProductSalesChannelType;
  totalAmount?: number;
}

export interface ProductSalesPerMonth {
  yearAndMonthAndProductId: string;
  month: number;
  year: number;
  giveaways?: number;
  deadInventory?: number;
  purchasePrice?: number;
  manualOverriddenPurchasePrice?: number;
  product: ProductInSale;
  channelsSales: ChannelSales[];
}

export interface InventoryState {
  data: InventoryPerMonthRecord[];
  availableYears: number[];
  filters: {
    year: number;
  };
  monthlyInventory: undefined | InventoryPerMonthRecord;
  validating: boolean;
  isManufacturer: boolean
}

export interface ProductsState {
  data: any[];
  total: number;
  channels: ProductSalesChannel[];
  productInfoModal: {
    open: boolean;
    product: any;
  };
}

export interface SalesFilters {
  year: number;
  month: number;
  query: string;
  pageSize: number;
  page: number;
}

export interface SalesSate {
  data: ProductSalesPerMonth[];
  updatedData: ProductSalesPerMonth[];
  autoUpdate: boolean;
  total: number;
  lastUpdated: string;
  aggregated: {
    cogs: number;
    giveawaysSum: number;
    deadInventorySum: number;
  };
  filters: SalesFilters;
}

export interface EndingBalanceUpdate {
  amount: number | undefined;
  fromMonth: number;
  toMonth: number;
  spread: boolean;
}

export interface Company {
  id: string;
  name: string;
  isManufacturer: boolean;
}

export const PRODUCT_VARIANT_UNKNOWN_ID = '__unknown__';
