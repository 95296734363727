import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'secondary' does not exist on type '{}'.
    backgroundColor: (params) => (params.secondary ? theme.palette.grey[50] : theme.palette.primary[900]),
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'secondary' does not exist on type '{}'.
    color: (params: { secondary: string }) => (!params.secondary ? theme.palette.grey[50] : theme.palette.primary[900]),
    '&:hover': {
      backgroundColor: (params: { secondary: string }) => (params.secondary ? '#ddd' : '#5660a0'),
    },
  },
}));

export default function FormButton({ children, secondary, ...otherParams }: any) {
  const classes = useStyles({ secondary });

  return (
    <Button className={classes.button} {...otherParams}>
      {children}
    </Button>
  );
}
