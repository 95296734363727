import { Grid } from '@mui/material';
import NoteCard from '../../../../components/cards/NoteCard';
import { formatToUSD } from '../../../../utils/helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAggregated, selectFilters } from '../../salesSlice';
import { monthsList } from '../../../../utils/months';
import {isEqual} from "lodash";

export const SalesSummary = () => {
  const storeAggregated = useSelector(selectAggregated, isEqual);
  const filters = useSelector(selectFilters, isEqual);

  const headerText = `${monthsList[filters.month - 1]} ${filters.year}`;

  return (
    <Grid item xs={12} sm={4} lg={3} sx={{ textAlign: 'right' }}>
      <NoteCard narrow>
        <div>
          <p style={{ margin: '0px', fontWeight: 900 }}>{headerText}</p>

          <p style={{ margin: '0px' }}>
            COGS:{' '}
            <span>
              <strong>{formatToUSD(storeAggregated.cogs)}</strong>
            </span>
          </p>

          <p style={{ margin: '0px' }}>
            Givaways:{' '}
            <span>
              <strong>{formatToUSD(storeAggregated.giveawaysSum)}</strong>
            </span>
          </p>

          <p style={{ margin: '0px' }}>
            Dead Inventory:{' '}
            <span>
              <strong>{formatToUSD(storeAggregated.deadInventorySum)}</strong>
            </span>
          </p>
        </div>
      </NoteCard>
    </Grid>
  );
};
