import { Link, TableCell, TableRow } from '@mui/material';
import HtmlTooltip from '../../../components/HtmlTooltip';
// @ts-ignore
import placeholderImage from '../../../assets/images/placeholder-image.png';
import Typography from '@mui/material/Typography';
import { formatToUSD } from '../../../utils/helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import SalesOverrideAmount from './SalesOverrideAmount';
import { ProductSourceChannel } from './ProductSourceChannel';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanUpdateInventorySales } from '../../inventory/inventorySlice';
import { getProductAsync, selectChannels, setShowProductInfoModal } from '../../products/productsSlice';
import { findSaleById, setSalesRow } from '../salesSlice';
import { IntegrationType, ProductSalesChannel } from '../../../types';
import { SalesUSDAmount } from './SalesUSDAmount';
import { isEqual } from 'lodash';
import SalesInputCell from './SalesInputCell';
import { SalesOriginalPurchasePrice } from './SalesOriginalPurchasePrice';

const useStyles = makeStyles((theme) => ({
  productCell: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    '& .image-wrapper': {
      width: '80px',
      height: '80px',
      flexShrink: 0,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      border: `1px solid ${theme.palette.grey[200]}`,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
    '& h6': {
      fontSize: '14px',
      fontWeight: 400,
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    },
  },
  tooltip: {
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
}));

export const SalesListRow = React.memo(({ rowId }: { rowId: string }) => {
  const classes = useStyles();
  const [cogs, setCogs] = useState(0);
  const [totalOtherTransactions, setTotalOtherTransactions] = useState(0);
  const isInventoryApprovedByUser = useSelector(selectCanUpdateInventorySales);
  const storeChannels = useSelector(selectChannels, isEqual);
  const shopifyChannel = storeChannels.find((ch: ProductSalesChannel) => ch.channel === 'Shopify');
  const row = useSelector(findSaleById(rowId), isEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (row.channelsSales.length) {
      setCogs(
        row.channelsSales.reduce(
          (acc: any, channelSales: any) =>
            acc + (channelSales.totalAmount ?? 0) * (row.manualOverriddenPurchasePrice ?? row.purchasePrice ?? 0),
          0,
        ),
      );
    }
  }, [row.channelsSales, row.manualOverriddenPurchasePrice, row.purchasePrice]);

  useEffect(() => {
    const price = (row.manualOverriddenPurchasePrice ?? row.purchasePrice) || 0;
    const total = (row.deadInventory ?? 0) * price + (row.giveaways ?? 0) * price;
    setTotalOtherTransactions(total);
  }, [row.purchasePrice, row.deadInventory, row.manualOverriddenPurchasePrice, row.giveaways]);

  const editCellPropsChangeHandler = useCallback(
    (value: any, field: string) => {
      value = value === null ? undefined : value;
      dispatch(
        setSalesRow({
          ...row,
          [field]: value,
        }),
      );
    },
    [dispatch, row],
  );

  const updateManualProduct = () => {
    // @ts-expect-error ts-(2554) FIXME: Expected 0 arguments, but got 1.
    dispatch(getProductAsync({ productId: row.product.productId }))
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'unwrap' does not exist on type 'AsyncThu... Remove this comment to see the full error message
      .unwrap()
      .then((result: any) => {
        dispatch(setShowProductInfoModal({ open: true, product: result.data }));
      });
  };

  return (
    <TableRow>
      <TableCell align="left">
        <div className={classes.productCell}>
          <HtmlTooltip
            className={classes.tooltip}
            placement="right"
            title={
              <div className="tooltip-content">
                <img src={row.product.image || placeholderImage} alt={'No image'} loading="lazy" />
              </div>
            }
          >
            <div className="image-wrapper">
              <img src={row.product.image || placeholderImage} alt={'No image'} loading="lazy" />
            </div>
          </HtmlTooltip>

          <Typography variant="caption" color="black">
            {row.product.integrationType === IntegrationType.USER ? (
              <Link href="#" underline="always" onClick={updateManualProduct}>
                {row.product.name}
              </Link>
            ) : (
              row.product.name
            )}
          </Typography>
        </div>
      </TableCell>

      <TableCell align="center">
        <Typography variant="caption" color="black" maxWidth={150} noWrap display="block">
          {row.product.sku}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <ProductSourceChannel product={row.product} channels={storeChannels} />
      </TableCell>

      <TableCell sx={{ pl: 5, borderRight: '1px dashed #ddd', position: 'relative' }} scope="row" align="center">
        <SalesInputCell
          width="70px"
          style={{ width: '70px' }}
          value={row.manualOverriddenPurchasePrice ?? row.purchasePrice}
          format
          field="manualOverriddenPurchasePrice"
          onChange={editCellPropsChangeHandler}
          type="number"
          inputProps={{ min: 0 }}
          disabled={isInventoryApprovedByUser || shopifyChannel.updatePurchasePriceFromChannel}
          fake
        />
        <SalesOriginalPurchasePrice
          disabled={isInventoryApprovedByUser}
          price={row.purchasePrice}
          manualOverriddenPurchasePrice={row.manualOverriddenPurchasePrice}
        />
      </TableCell>

      {storeChannels.map((inventoryChannel: any) => (
        <TableCell
          key={`${row.yearAndMonthAndProductId}_${inventoryChannel.channel}`}
          sx={{ position: 'relative' }}
          align="center"
        >
          <SalesOverrideAmount
            row={row}
            editCellPropsChangeHandler={editCellPropsChangeHandler}
            inventoryChannel={inventoryChannel}
            canUpdateInventorySales={isInventoryApprovedByUser}
          />
        </TableCell>
      ))}

      <TableCell sx={{ pl: 5, borderRight: '1px dashed lightgray' }} align="center">
        <Typography variant="caption" color={isInventoryApprovedByUser ? '' : 'black'}>
          {formatToUSD(cogs)}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ position: 'relative' }}>
        <SalesInputCell
          width="70px"
          style={{ width: '70px' }}
          value={row.giveaways}
          field="giveaways"
          onChange={editCellPropsChangeHandler}
          type="number"
          disabled={isInventoryApprovedByUser}
          fake
        />
        <SalesUSDAmount
          disabled={isInventoryApprovedByUser}
          price={row.manualOverriddenPurchasePrice ?? row.purchasePrice}
          units={row.giveaways}
        />
      </TableCell>

      <TableCell align="center" sx={{ position: 'relative' }}>
        <SalesInputCell
          width="70px"
          style={{ width: '70px' }}
          value={row.deadInventory}
          field="deadInventory"
          onChange={editCellPropsChangeHandler}
          type="number"
          disabled={isInventoryApprovedByUser}
          fake
        />
        <SalesUSDAmount
          disabled={isInventoryApprovedByUser}
          price={row.manualOverriddenPurchasePrice ?? row.purchasePrice}
          units={row.deadInventory}
        />
      </TableCell>
    </TableRow>
  );
});
