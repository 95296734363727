import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from '../dashboard/dashboardSlice';
import {
  deleteProduct,
  fetchChannels,
  fetchProduct,
  fetchProducts,
  storeProduct,
  updateChannel,
  updateProduct,
} from './productsAPI';
import { ProductSalesChannel, ProductsState } from '../../types';

const initialState: ProductsState = {
  data: [],
  total: 0,
  channels: [],
  productInfoModal: {
    open: false,
    product: undefined,
  },
};

const callAPIWithLoader = async (callback: any, dispatch: any, ...args: any[]) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  dispatch(startLoading());
  try {
    const response = await callback(...args);
    return response;
  } finally {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    dispatch(stopLoading());
  }
};

export const getChannelsAsync = createAsyncThunk('products/getChannels', async (_, { dispatch }) => {
  const response = await callAPIWithLoader(fetchChannels, dispatch);
  return response.data;
});

export const updateChannelAsync = createAsyncThunk('updateChannel/updateProduct', (data, { dispatch }) =>
  callAPIWithLoader(updateChannel, dispatch, data),
);

export const getProductsAsync = createAsyncThunk('products/getProducts', (_, { dispatch, getState }) =>
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  callAPIWithLoader(fetchProducts, dispatch, getState().products.filters),
);

export const getProductAsync = createAsyncThunk('products/getProduct', (product, { dispatch, getState }) =>
  callAPIWithLoader(fetchProduct, dispatch, product),
);

export const storeProductAsync = createAsyncThunk('products/storeProduct', (product, { dispatch }) =>
  callAPIWithLoader(storeProduct, dispatch, product),
);

export const updateProductAsync = createAsyncThunk('products/updateProduct', (data, { dispatch }) =>
  callAPIWithLoader(updateProduct, dispatch, data),
);

export const deleteProductAsync = createAsyncThunk('products/deleteProduct', (id, { dispatch }) =>
  callAPIWithLoader(deleteProduct, dispatch, id),
);

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.data = action.payload;
    },
    setShowProductInfoModal: (state, action) => {
      state.productInfoModal = action.payload;
    },
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(builder: any, t: any) => void' is not assig... Remove this comment to see the full error message
  extraReducers: (builder: any, t: any) => {
    builder
      .addCase(getProductsAsync.fulfilled, (state: any, action: any) => {
        state.data = action.payload.data;
        state.total = action.payload.data.length;
        state.filters.page = 0;
      })
      .addCase(getChannelsAsync.fulfilled, (state: any, action: any) => {
        state.channels = action.payload;
      })
      .addCase(updateChannelAsync.fulfilled, (state: ProductsState, action: any) => {
        const channelIndex = state.channels.findIndex(({ channel }) => channel === action.payload.data.channel);

        state.channels = [
          ...state.channels.slice(0, channelIndex),
          action.payload.data,
          ...state.channels.slice(channelIndex + 1),
        ];
      })
      .addCase(deleteProductAsync.fulfilled, (state: any, action: any) => {
        state.data = state.data.filter((prod: any) => prod.id !== action.payload.data.id);
        state.total -= 1;
      })
      .addCase(storeProductAsync.fulfilled, (state: any, action: any) => {
        state.data = [action.payload.data, ...state.data];
        state.filters.page = 0;
        state.total += 1;
      });
    // .addMatcher(
    //   (action) => /^products.*rejected?/.test(action.type),
    //   (_, action) => {
    //     NotificationManager.error(action.error.message);
    //     console.log(action.error);
    //   },
    // );
    // .addMatcher(
    //   (action) => /^products\/(store|update|set|delete).*fulfilled?/.test(action.type),
    //   (_, action) => {
    //     NotificationManager.success(action.payload.message);
    //   },
    // );
  },
});

export const { setShowProductInfoModal } = productsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectChannels = (state: any) => state.products.channels;
export const selectFilters = (state: any) => state.products.filters;
export const selectProductInfoModal = (state: any) => state.products.productInfoModal;
export default productsSlice.reducer;
