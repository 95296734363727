import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

// material-ui
import { makeStyles } from '@mui/styles';

// third-party
import clsx from 'clsx';
import { getTooltipsAsync } from '../../views/dashboard/dashboardSlice';
import { useDispatch } from 'react-redux';
import Settings from '../../views/settings/Settings';
import { getCompany } from '../../views/settings/settingsSlice';
import { getChannelsAsync } from '../../views/products/productsSlice';

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  content: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'typography' does not exist on type 'Defa... Remove this comment to see the full error message
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
    transition: theme.transitions.create('margin', {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
      easing: theme.transitions.easing.sharp,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

// ===========================|| MAIN LAYOUT ||=========================== //

const MainLayout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTooltipsAsync());
    dispatch(getCompany());
    dispatch(getChannelsAsync());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <main className={clsx([classes.content])}>
        <Outlet />
      </main>
      <Settings />
    </div>
  );
};

export default withAuthenticationRequired(MainLayout);
