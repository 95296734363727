import {styled} from "@mui/material";
import FormButton from "./FormButton";

export const ActionButton = styled(FormButton)`
  background-color: #56bbd6;
  &:hover {
      background-color: rgb(86, 187, 214, 0.7);
      border-color: #56bbd6;
      box-shadow: none;
  },
`;
