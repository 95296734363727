import { createClient } from 'contentful';

class ContentfulApi {
  client: any;

  constructor() {
    this.client = createClient({
      space: '1iie5ny5gpxh',
      accessToken: '9WKouLyJJTGfOczeU8rq_DT3nAiCPY-5_2Ed0Z58VLE',
      host: 'preview.contentful.com',
    });
  }

  async getEntries(query: any) {
    return this.client.getEntries(query);
  }
}

export default new ContentfulApi();
