import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%)',
    zIndex: 9999,
  },
}));

function Loader({ show }: any) {
  const classes = useStyles();
  return (
    <Box className={classes.root} display={show ? 'block' : 'none'}>
      <CircularProgress color="primary" size={70} />
    </Box>
  );
}

export default Loader;
