import {styled, TableCell} from "@mui/material";

export const InventoryTableCell = styled(TableCell)(({ theme }) => ({
    verticalAlign: 'top',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.down('lg')]: {
        paddingLeft: '5px',
        paddingRight: '5px',
    },
}));
