import React from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Inventory from 'views/inventory/Inventory';

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
  path: '/',

  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Inventory />,
    },
  ],
};

export default MainRoutes;
