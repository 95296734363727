import { styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { formatToUSD } from '../../../utils/helpers';

const AmountDisplay = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 121px;
  top: 32px;
  text-align: center;
  z-index: 0;
`;

interface SalesUSDAmountProps {
  units: number | undefined;
  price: number | undefined;
  disabled: boolean;
}

export const SalesUSDAmount = ({ units, price, disabled }: SalesUSDAmountProps) => {
  const [amount, setAmount] = useState<number | undefined>();
  useEffect(() => {
    units && price ? setAmount(parseFloat(new BigNumber(units).times(price).toFixed(2))) : setAmount(undefined);
  }, [units, price]);

  return amount ? (
    <AmountDisplay color={disabled ? '' : 'black'} variant="caption">{`${formatToUSD(amount)}`}</AmountDisplay>
  ) : null;
};
