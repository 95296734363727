import React, { lazy } from 'react';

// project imports
import MinimalLayout from '../layout/MinimalLayout';
import Loadable from '../components/Loadable';

// login routing
// @ts-ignore
const AuthLogin = Loadable(lazy(() => import('views//login')));

// ===========================|| AUTH ROUTING ||=========================== //

const LoginRoutes = {
  path: 'login',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin />,
    },
  ],
};

export default LoginRoutes;
