import NoteCard from '../../../../components/cards/NoteCard';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMonthlyInventory } from '../../../inventory/inventorySlice';
import { selectFilters } from '../../salesSlice';
import { isEqual } from 'lodash';
import { monthsList } from '../../../../utils/months';
import { getDaysInMonth, setMonth } from 'date-fns';

export const SalesNote = () => {
  const allMonths = useRef(monthsList);
  const monthlyInventory = useSelector(selectMonthlyInventory);
  const storeSalesFilters = useSelector(selectFilters, isEqual);

  const [currentMonth, setCurrentMonth] = useState(allMonths.current[storeSalesFilters.month - 1]);
  const [lastDay, setLastDay] = useState<number | undefined>();

  useEffect(() => {
    setLastDay(getDaysInMonth(setMonth(new Date(), storeSalesFilters.month - 1)));
    setCurrentMonth(allMonths.current[storeSalesFilters.month - 1]);
  }, [storeSalesFilters.month]);

  return monthlyInventory.approvedByUser ? (
    <NoteCard>
      Previously reported months are only available to view.
      <br />
      But no worries, if you have any further changes, you can make them directly to the Monthly Inventory Tracker.
    </NoteCard>
  ) : (
    <NoteCard>
      Use this tool to help calculate your COGS and other inventory adjustments. We already pulled in your data from
      Shopify (cost per unit as of {lastDay}-{currentMonth} and units sold during {currentMonth} for each product). Just
      add the missing details below for Wholesale, Giveaways and Dead Inventory, if any, and you should be good to go :)
      <br />
      If your Shopify data is not accurate, feel free to input the numbers manually instead.
      <br /> Once you select Sync to Tracker, the results are synced to your Monthly Inventory Tracker.
    </NoteCard>
  );
};
